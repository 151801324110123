import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PvpBetEvent from '../../components/PvpBetEvent';
import LoadingSpinner from '../../components/_shared/LoadingSpinner';
import Page from '../../components/_shared/Page';
import LoginModal from '../../components/LoginModal';
import UserModal from '../../components/userModal';
import { isEmpty } from 'lodash';
import { logEvent } from '../../analytics/amplitude';
import {
  CHALLENGE_CLICKED,
  EVENT_PAGE_SHOWN,
  TUTORIAL_STEP_1_CLICKED,
  TUTORIAL_STEP_2_CLICKED,
  TUTORIAL_STEP_2_SHOWN,
} from '../../analytics/types';
import { AppContext } from '../../app-context';
import pick from 'lodash/pick';
import { SpotlightTour } from '../../components/_shared/Spotlight/lib/context';
import Spotlight from '../../components/_shared/Spotlight';
import Ogmi from '../../../assets/svgs/logos/ogmi3.svg';
import usePageMeta from '../../../hooks/usePageMeta';
import { useAppDispatch } from '../../redux/store';
import { resetTrivia } from '../../redux/actions/trivia';
import { useEvent } from '../../hooks/event';
import { useTopPlayers } from '../../hooks/top-players';
import { useChallenges } from '../../hooks/challenge/useChallenges';
import { resetScore } from '../../redux/actions/user';
import useFeatures from '../../hooks/useFeatures';

function PvpBetEventWrapper() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { event: eventResponse } = useEvent({ params });
  const { topPlayers: topPlayersResponse } = useTopPlayers({
    params: { event_id: eventResponse?.data?.id },
  });
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const { userData, appData } = useContext(AppContext);
  const { challenges } = useChallenges();
  const [isSpotlightOpen, setIsSpotlightOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get('from');
  const { variant } = useFeatures({});

  const filteredChallenges =
    variant.singleChallenge && appData.game_played <= 0
      ? (challenges ?? []).filter((_, idx) => idx === 0)
      : (challenges ?? []).filter(({ id }) => !appData?.challengerIds?.includes(id));

  usePageMeta({});

  useEffect(() => {
    logEvent(EVENT_PAGE_SHOWN, {
      event_category: 'sports',
      event_topic: 'basketball',
    });
  }, []);

  useEffect(() => {
    if (param === 'result' && appData.game_played === 1) {
      logEvent(TUTORIAL_STEP_2_SHOWN);
      setIsSpotlightOpen(true);
    }
    if (isOpenLogin) navigate({ pathname: location.pathname, search: '' });
    dispatch(resetTrivia());
    dispatch(resetScore());
  }, [appData.game_played, param, isOpenLogin]);

  const handleOnClickChallenge = (challengeId) => {
    let funnel_step = 'regular';

    if ([0, 1].indexOf(challengeId) >= 0) {
      funnel_step = `tutorial_${challengeId}`;
    }

    logEvent(CHALLENGE_CLICKED, {
      funnel_step,
      game_mode: 'fwpvp',
      challenge_number: challengeId,
      user_id: userData?.user?.id,
    });
    navigate(`${challengeId}/matching`);
  };

  if (eventResponse.status === 200)
    return (
      <Page className="bg-primary" hasOpacityTransition>
        <SpotlightTour
          open={(variant.spotlight || appData.game_played === 1) && isSpotlightOpen}
          onClose={() => {
            setIsSpotlightOpen(false);
            if (appData.game_played === 1) {
              logEvent(TUTORIAL_STEP_2_CLICKED);
              setIsOpenLogin(true);
              return;
            }
            if (appData.game_played <= 0) {
              logEvent(TUTORIAL_STEP_1_CLICKED);
              handleOnClickChallenge(1);
            }
          }}
          Spotlight={Spotlight}
        >
          {variant.spotlight && isSpotlightOpen && (
            <img src={Ogmi} alt={''} className={'h-50 absolute z-50 top-[6%]'} />
          )}
          <PvpBetEvent
            event={eventResponse.data}
            challenges={filteredChallenges}
            topPlayersResponse={topPlayersResponse}
            user={userData?.user}
            userInfo={{ ...pick(appData, 'benefits', 'game_played') }}
            setIsOpenLogin={setIsOpenLogin}
            setIsSpotlightOpen={setIsSpotlightOpen}
            handleOnClickChallenge={handleOnClickChallenge}
            variant={variant}
          />
        </SpotlightTour>
        {isEmpty(userData?.user) ? (
          <LoginModal
            isOpen={isOpenLogin}
            setIsOpenLogin={setIsOpenLogin}
            userInfo={{ ...pick(appData, ['user_rewards', 'user_trophies']) }}
          />
        ) : (
          <UserModal
            isOpen={isOpenLogin}
            setIsOpenLogin={setIsOpenLogin}
            user={userData?.user}
            userInfo={{ ...pick(appData, 'benefits', 'game_played') }}
          />
        )}
      </Page>
    );

  // if (eventResponse.status === 'error') throw Error;

  if (isEmpty(eventResponse?.data) || isEmpty(topPlayersResponse?.data))
    return (
      <Page className="bg-primary">
        <LoadingSpinner show />
      </Page>
    );
  return null;
}

export default PvpBetEventWrapper;
