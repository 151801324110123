import blankProfileImage from '../../../assets/images/blank_profile_picture.png';
import React from 'react';

interface ICustomImage {
  imageUrl: string;
  padding?: string | number;
  size?: number;
  borderColor?: string;
}
const CustomImage = ({
  imageUrl,
  padding = 3,
  size = 50,
  borderColor = 'bg-white',
}: ICustomImage) => (
  <div
    className={`flex-shrink-0 ${borderColor}`}
    style={{
      width: size,
      height: size,
      borderRadius: 80,
      clipPath: 'polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)',
      padding: padding,
    }}
  >
    <img
      style={{
        width: size - 6,
        height: size - 6,
        borderRadius: 80,
        clipPath: 'polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className={'text-center object-cover'}
      src={imageUrl || blankProfileImage}
    />
  </div>
);

export default CustomImage;
