import React, { useEffect, useState } from 'react';
import { IAnswer, IOpponent, IOpponentRound, IUser } from '../../../../../types/api';
import { selectFirstName } from '../../../../../helpers/helpers';
import CustomImage from '../../../_shared/CustomImage';
import { isEmpty, isNull } from 'lodash';

interface IScoreDisplay {
  user: IUser;
  userRoundScore: number;
  opponent: IOpponent;
  userAnsweredIn: number;
  opponentRound: IOpponentRound;
  userAnswer: IAnswer;
}

const ScoreDisplay = (props: IScoreDisplay) => {
  const { opponent, user, userRoundScore, userAnswer, opponentRound } = props;

  return (
    <div className="col-span-1">
      {!isEmpty(opponent) ? (
        <div className={'flex items-center tex'}>
          <CustomImage imageUrl={user?.profile_image_url} size={42} borderColor={'bg-gray-200'} />
          <div className={'pl-2 truncate'}>
            <p className={'font-bold text-xs text-slate-500 truncate'}>
              {user?.display_name ? selectFirstName(user.display_name) : 'You'}
            </p>
            <p
              className={`text-center text-lg font-bold ${
                !userAnswer ? 'text-white' : userAnswer?.correct ? 'text-green-400' : 'text-red-400'
              }`}
            >
              {userRoundScore}
            </p>
          </div>
        </div>
      ) : (
        <>
          <p className="font-bold text-slate-500 text-xs">Score</p>
          <p className={`text-lg font-bold text-green-400`}>{userRoundScore}</p>
        </>
      )}
    </div>
  );
};

export default ScoreDisplay;
