import { IUser } from '../../../types/api';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getToken, getUser } from '../../redux/actions/user';
import { identify } from '../../analytics/amplitude';
import { ID_USER_EMAIL } from '../../analytics/types';

interface IUseUserReturn {
  user: IUser;
  token: string;
  logout: () => void;
}

export const useUser = (): IUseUserReturn => {
  const baseUrl = process.env.REACT_APP_BACKEND_API_URL;
  const dispatch = useAppDispatch();
  const { user, token } = useAppSelector((state) => state.user);

  useEffect(() => {
    if(user?.email) {
      identify(ID_USER_EMAIL, user?.email)
    }
  }, [user?.email])

  useEffect(() => {
    // Define the headers for your request
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    // Define the options for your request
    const requestOptions = {
      method: 'GET',
      headers: headers,
    };
    fetch(baseUrl + '/users/me', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        dispatch(getUser(data));
      })
      .catch((error) => {
        console.log('There has been a problem with your fetch operation: ', error);
        if (error.response?.status === 401) {
          dispatch(getToken(null));
        }
      });
  }, [token]);

  const logout = () => {
    dispatch(getToken(null));
    dispatch(getUser(null));
  };

  return {
    user,
    token,
    logout,
  };
};
