export const GET_USER = '@get/user';
export const GET_TOKEN = '@get/token';
export const SET_CHALLENGE_ID = '@set/challengeIds';
export const SET_GAME_PLAYED = '@set/game_played';
export const SET_BENEFITS = '@set/benefits';
export const SET_SCORE = '@set/score';
export const SET_USER_SCORE = '@set/user_score';
export const SET_RESET_SCORE = '@set/reset_score';

export const getUser = (payload) => ({
  type: GET_USER,
  payload,
});

export const getToken = (payload) => ({
  type: GET_TOKEN,
  payload,
});

export const setChallengeIds = (payload) => ({
  type: SET_CHALLENGE_ID,
  payload,
});

export const setGamePlayed = () => ({
  type: SET_GAME_PLAYED,
});

export const setBenefits = (payload) => ({
  type: SET_BENEFITS,
  payload,
});
export const setScore = (payload) => ({
  type: SET_SCORE,
  payload,
});

export const setUserScore = (payload) => ({
  type: SET_USER_SCORE,
  payload,
});

export const resetScore = () => ({
  type: SET_RESET_SCORE,
});
