import { Action } from '../../types';
import { IUser } from '../../../../types/api';
import {
  GET_TOKEN,
  GET_USER,
  SET_BENEFITS,
  SET_CHALLENGE_ID,
  SET_GAME_PLAYED,
  SET_RESET_SCORE,
  SET_USER_SCORE,
} from '../../actions/user';
import { uniq } from 'lodash';
import { identify } from '../../../analytics/amplitude';
import {
  ID_CHALLENGES_LOST,
  ID_CHALLENGES_PLAYED,
  ID_CHALLENGES_WON,
  ID_MONEY_BALANCE,
} from '../../../analytics/types';

export interface IUserState {
  user: IUser;
  token: string | null;
  challengerIds: Array<string>;
  game_played: number;
  user_score: number;
  benefits: {
    trophies: number;
    rewards: number;
  };
}

const defaultState: IUserState = {
  user: null,
  token: null,
  challengerIds: [],
  game_played: 0,
  user_score: 0,
  benefits: {
    trophies: 0,
    rewards: 0,
  },
};

const userReducer = (state = defaultState, { type, payload }: Action) => {
  switch (type) {
    case GET_USER: {
      return {
        ...state,
        user: payload,
      };
    }
    case GET_TOKEN: {
      return {
        ...state,
        token: payload,
      };
    }
    case SET_CHALLENGE_ID: {
      return {
        ...state,
        challengerIds: uniq([...state.challengerIds, payload]),
      };
    }
    case SET_GAME_PLAYED: {
      const game_played = state.game_played + 1;

      identify(ID_CHALLENGES_PLAYED, game_played);

      return {
        ...state,
        game_played,
      };
    }
    case SET_USER_SCORE: {
      const { isCorrect, timeDifference } = payload;
      const newScore = state.user_score + Math.floor(isCorrect ? 100 - timeDifference * 10 : 1);

      return {
        ...state,
        user_score: Math.abs(timeDifference > 10 ? state.user_score : newScore),
      };
    }
    case SET_BENEFITS: {
      const { doesTheUserWon, wasADraw, reward } = payload;
      const trophyWon =
        state?.benefits?.trophies === 0 && !doesTheUserWon && !wasADraw
          ? 0
          : doesTheUserWon
          ? 3
          : wasADraw
          ? 1
          : -1;
      const userReward =
        state?.benefits?.rewards === 0 && !doesTheUserWon
          ? 0
          : doesTheUserWon
          ? state?.benefits?.rewards + reward
          : state?.benefits?.rewards;

      const successRate =
        state.benefits?.trophies % 3 === 0 ? 1 : 1 - state.benefits.trophies / state.game_played;

      let challenges_won = Math.floor(state.game_played * successRate);

      challenges_won = Math.max(challenges_won, 0);

      let challenges_lost = state.game_played - challenges_won;

      challenges_lost = Math.max(challenges_lost, 0);

      identify(ID_MONEY_BALANCE, userReward);

      identify(ID_CHALLENGES_WON, challenges_won);

      identify(ID_CHALLENGES_LOST, Math.min(state.game_played, challenges_lost));

      return {
        ...state,
        benefits: {
          ...state.benefits,
          trophies: state?.benefits?.trophies + trophyWon,
          rewards: userReward,
        },
      };
    }
    case SET_RESET_SCORE: {
      return {
        ...state,
        user_score: 0,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
