import { useQuery } from "react-query";
import api from "./../../config/api";

export default function handler({params}) {
  const response = useQuery(['event', params.slug], async () => {
    const response = await api.fetchEvent({params});
    return response.data
  }, {
    refetchOnWindowFocus: false
  })
  
  return response;
}