import { Popover } from 'react-tiny-popover';
import React from 'react';
import { Preloader, ThreeDots } from 'react-preloader-icon';

interface IThinkPopup {
  isOpen: boolean;
}
const ThinkPopup = ({ isOpen }: IThinkPopup) => (
  <Popover
    isOpen={isOpen}
    positions={['bottom']}
    padding={10}
    reposition={true} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
    content={
      <div className={'relative'}>
        <div
          className={
            'flex items-center  justify-between font-bold text-gray-800 px-4 py-3 bg-slate-100 text-xs text-center border-2 border-gray-500 rounded-lg'
          }
        >
          <span className={'text-xl'}>
            <Preloader use={ThreeDots} strokeColor={'#333'} size={30} strokeWidth={8} />
          </span>
        </div>
        <div
          style={{
            top: '-10px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '15px',
            height: '15px',
            clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
          }}
          className={'absolute bg-slate-100 left-auto'}
        />
      </div>
    }
  >
    <div />
  </Popover>
);

export default ThinkPopup;
