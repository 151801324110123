import gCash from '../../../../../../assets/images/g-cash.png';
import pPal from '../../../../../../assets/images/pay-pal.png';
import pMaya from '../../../../../../assets/images/pay-maya.png';
import mCard from '../../../../../../assets/images/master-card.png';
import visa from '../../../../../../assets/images/visa.png';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { motion } from 'framer-motion';
import { logEvent } from '../../../../../../analytics/amplitude';
import { DEPOSIT_SCREEN_PAYMENT_OPTION_SELECTED } from '../../../../../../analytics/types';
import { IUser } from '../../../../../../../types/api';

const deposits = [5, 10, 15, 25];

interface IDepositContent {
  funnel_step: string;
  user?: IUser;
  setDepositState: Dispatch<
    SetStateAction<{
      show: boolean;
      processing: boolean;
      failed: boolean;
    }>
  >;
}

const DepositContent = ({ setDepositState, funnel_step, user }: IDepositContent) => {
  const [amount, setAmount] = useState(deposits[0]);
  const bonus = 5;

  return (
    <>
      <div className={'flex justify-between w-full px-5 py-2 bg-cyan-50 rounded-2xl'}>
        {deposits.map((deposit) => (
          <motion.div
            key={`deposit_${deposit}`}
            initial={{ scale: 1 }}
            whileTap={{ scale: 0.9 }}
            whileHover={{ zIndex: 0.5 }}
            className={`hover:cursor-pointer py-2 w-[23%] text-center border rounded-lg font-bold  ${
              amount === deposit
                ? 'border-green-400 text-green-400'
                : 'border-gray-300 text-gray-400'
            }`}
            onClick={() => {
              logEvent(DEPOSIT_SCREEN_PAYMENT_OPTION_SELECTED, {
                funnel_step,
                user_id: user?.id,
                payment_amount: amount,
              });
              setAmount(deposit);
            }}
          >
            ${deposit}
          </motion.div>
        ))}
      </div>
      <div className={'w-full px-5 py-6 bg-cyan-50 rounded-2xl'}>
        <div className={'flex justify-between items-center font-bold'}>
          <div className={'text-gray-400'}>Deposit amount</div>
          <div className={'text-primary text-2xl opacity-80'}>${amount}</div>
        </div>
        <div className={'flex justify-between items-center mt-4 text-green-400 font-bold'}>
          <div>Welcome bonus</div>
          <div className={'text-2xl'}>+${bonus}</div>
        </div>
        <div className={'border mt-4'} />
        <div className={'flex justify-between items-center mt-4 font-bold'}>
          <div className={'text-gray-400'}>Total amount</div>
          <div className={'text-primary text-2xl opacity-80'}>+${amount + bonus}</div>
        </div>
      </div>
      <div className={'text-gray-400 font-bold text-2xl my-2'}>Choose a payment provider</div>
      <div className={'w-full flex justify-between mb-2'}>
        {[gCash, pPal, pMaya, mCard, visa].map((p, i) => (
          <img
            key={`payment-method_${i}`}
            src={p}
            alt={''}
            className={'hover:cursor-pointer'}
            onClick={() => {
              setDepositState((prevState) => ({ ...prevState, processing: true, show: false }));
            }}
          />
        ))}
      </div>
    </>
  );
};

export default DepositContent;
