import React, { useRef } from "react";
import Sheet, { SheetRef } from "react-modal-sheet";
import LoginProviders from "../LoginProviders";

function LoginSheet({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const ref = useRef<SheetRef>();

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={[400, 400, 0]}
      initialSnap={1}
      className="max-w-md mx-auto"
    >
      <Sheet.Container>
        <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
          <LoginProviders />
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  )
}

export default LoginSheet;