import { useQuery } from 'react-query';
import api from '../../../../config/api';
import { useEffect, useState } from 'react';
import { IUser } from '../../../../types/api';
import { Challenge } from '../../../mocks/data';

interface IUseMatchingReturn {
  opponent: IUser;
  challenge: Challenge;
  startChallenge: boolean;
}

const useMatching = ({ slug, challenge_id }: { slug: string; challenge_id: string | number }): IUseMatchingReturn => {
  const [opponent, setOpponent] = useState();
  const [startChallenge, setStartChallenge] = useState(false);
  const [challenge, setChallenge] = useState();

  const response = useQuery(['matching', slug], async () => {
    const response = await api.createMatching({ params: { slug, challenge_id } });
    return response.data;
  });

  useEffect(() => {
    const data = response?.data;
    if (data) {
      setOpponent(data.opponent);
      setChallenge(data.challenge);
    }
  }, [response?.data]);

  useEffect(() => {
    if (opponent) {
      setStartChallenge(true)
    }
  }, [opponent]);

  return {
    opponent,
    challenge,
    startChallenge,
  };
};

export default useMatching;
