import React, { useState, useRef } from 'react';
import penIcon from '../../../../assets/svgs/pen.svg';
import { OgmiLogoButton } from '../LogoButton';
import { BackButton, DiscordButton, TwitterButton, ShareButton } from './lib';

interface IHeader {
  title?: string;
  hasBack?: boolean;
  backLink?: string;
  hasShare?: boolean;
  hasDiscord?: boolean;
  hasTwitter?: boolean;
  shareTitle?: string;
  shareDescription?: string;
  onShare?: () => void;
  editable?: boolean;
  onEdit?: (value: string) => void;
}

const Header = (props: IHeader) => {
  const {
    title = 'OGMI',
    hasBack,
    hasShare,
    hasDiscord,
    hasTwitter,
    backLink,
    shareTitle,
    shareDescription,
    onShare,
    editable,
    onEdit,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const inputRef = useRef(null);

  const handleEdit = () => {
    if (editable && isEditing === false) {
      setIsEditing(true);
      setTimeout(() => inputRef.current && inputRef.current.focus(), 0);
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    onEdit(editTitle);
  };

  const handleCancel = () => {
    setIsEditing(false);
    // Reset the edited title to the initial title
    setEditTitle(title);
  };

  return (
    <div className="relative mb-28 max-w-md">
      <div className="flex p-4 items-center bg-primary header-bottom-shadow h-17 fixed z-30 h-20 w-full max-w-md">
        <div className="w-16">
          {hasBack && <BackButton backLink={backLink} />}
          {!hasBack && <OgmiLogoButton />}
        </div>

        <div className="flex flex-1 flex-row w-1/3 items-center mx-auto" onClick={handleEdit}>
          {!isEditing && (
            <p className="mx-auto text-xl text-white font-bold text-center truncate ellipisis">
              {editable && (
                <img src={penIcon} className="my-2 mr-3 text-white w-3 h-3 float-left" />
              )}
              {title}
            </p>
          )}
          {isEditing && (
            <>
              <button className="flex text-white font-bold mx-4" onClick={handleSave}>
                ✓
              </button>

              <input
                ref={inputRef}
                className="flex flex-1 bg-primary text-xl text-white font-bold text-center truncate ellipisis"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
              />
              <button className="flex text-white font-bold mx-4" onClick={handleCancel}>
                X
              </button>
            </>
          )}
        </div>

        <div className="flex space-x-2 w-16 justify-center">
          {hasTwitter && <TwitterButton />}
          {hasDiscord && <DiscordButton />}
          {hasShare && (
            <ShareButton onShare={onShare} title={shareTitle} description={shareDescription} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
