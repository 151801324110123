import { useQuery } from 'react-query';
import api from '../../../config/api';
import { IWagerChallenge } from '../../../types/api';

interface IUseChallengeReturn {
  challenges: IWagerChallenge[];
}

export const useChallenges = (): IUseChallengeReturn => {
  const { data: challenges } = useQuery(['challenges'], async () => {
    const response = await api.fetchChallenges({ params: {} });
    return response.data;
  });

  return {
    challenges,
  };
};