import { Action } from '../../types';
import { IEvent } from '../../../../types/api';
import { GET_EVENT } from '../../actions/events';

export interface IEventsState {
  event: { data: IEvent; status: number };
}

const defaultState: IEventsState = {
  event: {
    data: null,
    status: null,
  },
};

const userReducer = (state = defaultState, { type, payload }: Action) => {
  switch (type) {
    case GET_EVENT: {
      return {
        ...state,
        event: {
          data: payload.data,
          status: payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
