import React from 'react';

function AnalyticsDisplay({ titleLeftComponent, title = '', value = '-', titleClassName, valueClassName, blockClassName, rightComponent, onClick }: { titleLeftComponent?: React.ReactNode, title?: string; value?: string | number; titleClassName?: string; valueClassName?: string; blockClassName?: string; rightComponent?: React.ReactNode; onClick?: () => void }) {
  return <div onClick={onClick} className={`flex flex-col text-gray-200 font-bold text-center w-1/3 h-24 rounded-2xl justify-center relative ${onClick && 'cursor-pointer'}`}>
    <div className={`w-full absolute bg-blue-500 h-full opacity-40 rounded-2xl ${blockClassName}`} />
    <div className='flex flex-row justify-center items-center'>
      {titleLeftComponent}
      <p className={`text-blue-50 opacity-70 ${titleClassName}`}>{title}</p>
    </div>
    <div className='flex flex-row justify-center items-center'>
      <p className={`text-white text-2xl ${valueClassName}`}>{value}</p>
      {rightComponent}
    </div>
  </div>
}

export default AnalyticsDisplay;