import React, { useRef } from "react";
import Sheet, { SheetRef } from "react-modal-sheet";
import UserStatistcs from './components/UserStatistcs';
import XCircle from './../../../assets/svgs/x-circle.svg';

function UserStatisticsSheet({
  isOpen,
  onClose,
  data
}: {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
}) {
  const ref = useRef<SheetRef>();

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={[400, 400, 0]}
      initialSnap={1}
      className="max-w-md mx-auto"
      springConfig={{
        "stiffness": 300,
        "damping": 30,
        "mass": 1,
        duration: 10
      }}
    >
      <Sheet.Container>
        <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
          <div className='absolute right-2.5 top-2.5 cursor-pointer' onClick={onClose}>
            <img src={XCircle} />
          </div>
          <UserStatistcs userId={data?.userId} />
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  )
}

export default UserStatisticsSheet;