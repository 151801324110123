import React, { useEffect } from 'react';
import { Preloader, Spinning } from 'react-preloader-icon';
import BgImg from '../../../assets/images/half_center_circle.png';
import OgmiLogo from './../../assets/images/ogmi_neon.png';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '../../analytics/amplitude';
import { SPLASHSCREEN_SHOWN } from '../../analytics/types';

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const initTimeout = setTimeout(() => navigate('/events/basketball'), 3000);
    logEvent(SPLASHSCREEN_SHOWN)
    return () => clearTimeout(initTimeout);
  });

  return (
    <div className={'h-full w-full relative'}>
      <img src={BgImg} className={'block sm:hidden absolute w-full'} alt={''} />
      <div className={'absolute top-[20%] left-1/2 transform -translate-x-1/2'}>
        <img src={OgmiLogo} className='w-[128px] h-[127px]' alt={''} />
      </div>
      <div className={'absolute bottom-0 mb-20 text-center mx-auto w-full'}>
        <Preloader
          use={Spinning}
          size={60}
          strokeWidth={6}
          className={'mx-auto'}
          strokeColor="#fff"
          duration={1000}
        />
        <div className={'text-white font-semibold text-[20px] mt-4'}>Initializing game...</div>
      </div>
    </div>
  );
};

export default SplashScreen;
