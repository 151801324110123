import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSheet } from '../../../contexts/SheetContext/SheetContext';
import { IEvent, IUser, IWagerChallenge } from '../../../types/api';
import CustomImage from '../../../components/organisms/CustomImage';
import ChallengeCard from './lib/ChallengeCard';
import WalletSummary from './lib/WalletSummary';
import { IAppData } from '../../app-context';
import { useSpotlight } from '../_shared/Spotlight/lib/context';
import { logEvent } from '../../analytics/amplitude';
import {
  OTHERS_PROFILE_VIEW_CLICKED,
  SEEALL_BUTTON_CLICKED,
  TUTORIAL_STEP_1_SHOWN,
} from '../../analytics/types';
import { Ivariants } from '../../hooks/useFeatures';

interface IPvpBetEvent {
  event: IEvent;
  setIsOpenLogin: Dispatch<SetStateAction<boolean>>;
  challenges: IWagerChallenge[];
  user: IUser;
  userInfo: Partial<IAppData>;
  setIsSpotlightOpen: Dispatch<SetStateAction<boolean>>;
  topPlayersResponse: Record<string, any>;
  handleOnClickChallenge: (challenge: string) => void;
  variant: Ivariants;
}

export default function PvpBetEvent(props: IPvpBetEvent) {
  const {
    event,
    setIsOpenLogin,
    challenges,
    user,
    userInfo,
    setIsSpotlightOpen,
    topPlayersResponse,
    handleOnClickChallenge,
    variant,
  } = props;
  const navigate = useNavigate();
  const topPlayers = topPlayersResponse?.data;
  const { setSheet, setData } = useSheet();
  const { name, banner_image_url, description, profile_image_url } = event;
  const spotlightRef =
    userInfo.game_played <= 0
      ? useSpotlight('Click “Play” to start your \nfirst game!', 'top')
      : useSpotlight('Click here to view your \nstats & earnings', 'bottom');

  const handleSeeAll = () => {
    logEvent(SEEALL_BUTTON_CLICKED, {
      event_topic: 'basketball',
    });
    navigate('leaders-board');
  };

  const activateSpotlight = () => {
    if (typeof window !== 'undefined' && userInfo.game_played <= 0 && variant.spotlight) {
      const el = document?.getElementsByClassName('spotLight-click')?.[0];

      logEvent(TUTORIAL_STEP_1_SHOWN);

      el.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
      setIsSpotlightOpen(true);
    }
  };

  return (
    <div
      className="flex flex-1 flex-col overflow-y-scroll grow relative z-10"
      onClick={activateSpotlight}
    >
      <div className="relative mb-10">
        <img className="w-full h-56 object-cover -mt-8" src={banner_image_url} />
        <img
          className="h-[62px] w-[62px] rounded-full bg-indigo-1000 border-3 border-indigo-1000 left-3 -bottom-9 absolute"
          src={profile_image_url}
        />
      </div>

      <div className="flex flex-col px-4 z-10">
        <p className="text-white text-[24px] font-bold">{name}</p>
        <p className="text-white text-[15px] font-medium">{description}</p>
      </div>

      <div className="relative z-50 sticky top-[0px] bg-primary">
        <WalletSummary
          setIsOpenLogin={setIsOpenLogin}
          user={user}
          userInfo={userInfo}
          ref={userInfo.game_played === 1 ? spotlightRef : null}
        />
      </div>
      <div className="bg-primary sticky top-[50px] z-50 px-4">
        <div
          className={`text-white flex mt-2 items-center w-full ${
            topPlayers?.length === 0 && 'opacity-0 pointer-events-none'
          }`}
        >
          <p className="font-bold text-xl opacity-60">Top players</p>
          <p className="text-xs ml-auto cursor-pointer" onClick={handleSeeAll}>
            See all
          </p>
        </div>

        <div className="flex overflow-x-auto overflow-y-hidden w-full max-w-md no-scrollbar h-28">
          {topPlayers?.map((user: IUser, index) => (
            <div
              key={index}
              className="cursor-pointer flex flex-col m-4 ml-0 py-2 h-24 w-16 items-center"
              onClick={() => {
                logEvent(OTHERS_PROFILE_VIEW_CLICKED, {
                  profile_name: user.display_name,
                  profile_id: user.id,
                });
                setData({ userId: user.id });
                setSheet('user-statistics');
              }}
            >
              <CustomImage imageUrl={user.profile_image_url} />
              <p className="w-16 text-xs text-gray-300 text-center mt-2 truncate">{`${user.display_name}`}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-primary sticky top-[195px] font-bold text-white text-xl mb-4 pb-2 z-50 px-4">
        <span className="opacity-60">PvP Challenges</span>
      </div>
      <div className="relative sticky top-[320px] px-4">
        {challenges?.map((challenge, i) => (
          <ChallengeCard
            challenge={challenge}
            gamePlayed={userInfo?.game_played}
            setIsSpotlightOpen={setIsSpotlightOpen}
            handleOnClickChallenge={handleOnClickChallenge}
            variant={variant}
            key={i}
            ref={i === 0 && userInfo.game_played <= 0 ? spotlightRef : null}
          />
        ))}
      </div>
    </div>
  );
}
