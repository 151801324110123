//utils
import React from 'react';

export interface IOptions {
  size?: number;
  stroke?: number;
  arc?: boolean;
  angle?: number;
  sectorColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
  circleColor?: string;
  sectorFill?: string;
  fillCircle?: boolean;
  center?: number;
  radius?: number;
}

export const getSector = (returnD = false, options: IOptions) => {
  // Colors
  const sectorFill = options.arc ? 'none' : options.sectorColor;
  const sectorStroke = options.arc ? options.sectorColor : 'none';

  // Arc angles
  const firstAngle = options.angle > 180 ? 90 : options.angle - 90;
  const secondAngle = -270 + options.angle - 180;

  // Arcs
  const firstArc = getArc(firstAngle, options);
  const secondArc = options.angle > 180 ? getArc(secondAngle, options) : '';

  // start -> starting line
  // end -> will path be closed or not
  let end = '';
  let start = null;

  if (options.arc) {
    start = `M${options.center},${options.stroke / 2}`;
  } else {
    start = `M${options.center},${options.center} L${options.center},${options.stroke / 2}`;
    end = 'z';
  }

  // d path
  const d = `${start} ${firstArc} ${secondArc} ${end}`;

  if (returnD) return d;

  return (
    <path
      className="timer-sector"
      strokeWidth={'10'}
      fill={'#000000'}
      stroke={sectorStroke}
      d={d}
    />
  );
};

export const getCircle = (options: IOptions) => {
  const { fillCircle, arc, circleColor, center, radius, strokeColor } = options;
  const circleFill = fillCircle || arc ? circleColor : 'none';

  return (
    <circle
      cx={center}
      cy={center}
      r={radius}
      fill={`#4CCFFE40`}
      stroke={strokeColor}
      strokeWidth={0}
    />
  );
};

// Generates SVG arc strings
export const getArc = function (angle: number, options: IOptions) {
  const radians = (ang) => (ang / 180) * Math.PI;

  const x = options.center + options.radius * Math.cos(radians(angle));
  const y = options.center + options.radius * Math.sin(radians(angle));

  return `A${options.radius},${options.radius} 1 0 1 ${x},${y}`;
};
