import React from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';

import api from '../../config/api';
import instance from './../../config/axios';

import twitterBlue from './../../assets/svgs/logos/twitter_blue.svg';
import fb from './../../assets/images/fb_logo.png';
import gmail from './../../assets/images/gmail_logo.png';
import tiktokLogo from './../../assets/images/tiktok_logo.png';

import { saveTokenLocally } from './../../helpers/helpers';
import { useUser } from './../../contexts/UserContext';

const handleOnClickTwitter = () => {
    const callbackUrl = document.location.href;

    api.createAuthCallback({
        params: {
            method: 'twitter',
            callback_url: callbackUrl
        }
    }).then((response: any) => {
        const callbackUrl = response?.data?.callback_url

        if (callbackUrl) {
            window.location.replace(`${callbackUrl}`);
        }
    })
}

const handleOnClickTikTok = () => {
    const callbackUrl = document.location.href;

    api.createTikTokAuthCallback({
        params: {
            callback_url: callbackUrl
        }
    }).then((response: any) => {
        const callbackUrl = response?.data?.callback_url
        if (callbackUrl) {
            window.location.replace(`${callbackUrl}`);
        }
    })
}

const TwitterButton = () => {
    return (
        <div onClick={handleOnClickTwitter} className='flex border-2 border-blue-900 px-4 py-3 rounded-full w-84 text-blue-900 active:border-blue-500 active:text-blue-500 cursor-pointer'>
            <div className='flex w-12 items-center justify-center'>
                <img className='w-6 h-6' src={twitterBlue} />
            </div>
            <p className='text-xl font-bold px-'>Continue with Twitter</p>
        </div>
    )
}

const TikTokButton = () => {
    return (
        <div onClick={handleOnClickTikTok} className='flex border-2 border-blue-900 px-4 py-3 rounded-full w-84 text-blue-900 active:border-blue-500 active:text-blue-500 cursor-pointer'>
            <div className='flex w-12 items-center justify-center'>
                <img className='w-6 h-6 rounded-full' src={tiktokLogo} />
            </div>
            <p className='text-xl font-bold px-'>Continue with TikTok</p>
        </div>
    )
}

const LoginProviders = () => {
    const { setToken } = useUser();

    const handleGoogleSuccess = async (access_token) => {
        try {
            // Send the access token to your Rails API to authenticate the user and get the user data.
            const result = await instance.post('/social/google', { access_token: access_token, location: document.location.pathname });
            saveTokenLocally(result.data.token)
            setToken(result.data.token)

            // Push the event to dataLayer
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ 'event': 'Signup' });

            window.location.reload()
        } catch (err) {
            console.warn(err)
        }
    };

    const responseFacebook = async (response) => {
        try {
            const result = await instance.get(`/auth/facebook/callback?provider=facebook&access_token=${response.accessToken}&location=${document.location.pathname}`);

            saveTokenLocally(result.data.token)
            setToken(result.data.token)

            // Push the event to dataLayer
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ 'event': 'Signup' });
            window.location.reload()
        } catch (error) {
            console.warn('Facebook login error', error);
        }
    };

    const handleOnClickGoogle = useGoogleLogin({
        onSuccess: codeResponse => {
            handleGoogleSuccess(codeResponse.access_token)
        },
    });

    return (
        <div className='flex flex-col px-8 pt-8 pb-2 h-96 justify-between items-center'>
            <p className='font-bold text-2xl text-gray-400'>Login to Play</p>

            <div className='space-y-2'>
                <div onClick={() => handleOnClickGoogle()} className='flex border-2 border-blue-900 px-4 py-3 rounded-full text-blue-900 active:border-blue-500 active:text-blue-500 cursor-pointer'>
                    <div className='flex w-12 items-center justify-center'>
                        <img className='w-6 h-6' src={gmail} />
                    </div>
                    <p className='text-xl font-bold'>Continue with Gmail</p>
                </div>
                <FacebookLogin
                    appId="1687310931525698"
                    onSuccess={responseFacebook}
                    className='w-full'>
                    <div className='flex border-2 border-blue-900 px-4 py-3 rounded-full text-blue-900 active:border-blue-500 active:text-blue-500 cursor-pointer'>
                        <div className='flex w-12 items-center justify-center'>
                            <img className='w-6 h-6' src={fb} />
                        </div>
                        <p className='text-xl font-bold px-'>Continue with Facebook</p>
                    </div>
                </FacebookLogin>
                <TwitterButton />
                <TikTokButton />
            </div>

            <div>
                <p className='text-gray-500 text-lg text-center'>By continuing, you agree to our <a className='text-blue-900' href='/user-agreement'>User Agreement</a> and <a className='text-blue-900' href='/privacy-policy'>Privacy Policy</a></p>
            </div>
        </div >
    )
}

export default LoginProviders