import { useGoogleLogin } from '@react-oauth/google';
import api from '../../../config/api';
import { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { identify, logEvent } from '../../analytics/amplitude';
import { ID_INTEREST_GROUP_EVENT_ORIGIN, ID_SIGNUP_METHOD, ID_USER_ID, PLAYER_SIGNUP } from '../../analytics/types';
import { useUser } from '../user';
import { useAppDispatch } from '../../redux/store';
import { getToken } from '../../redux/actions/user';

interface IUserLoginReturn {
  facebookResponse: (response) => void;
  handleOnClickGoogle: () => void;
  handleOnClickTwitter: () => void;
  handleOnClickTikTok: () => void;
}

export const useLogin = ({
  setIsOpenLogin,
}: {
  setIsOpenLogin?: Dispatch<SetStateAction<boolean>>;
}): IUserLoginReturn => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const arrPath = pathname.split('/');
  const rewardPath = arrPath[arrPath.length - 1];
  const {user} = useUser();

  const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

  const handleGoogleSuccess = async (access_token) => {
    fetch(baseUrl + '/social/google', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_token: access_token,
        location: document.location.pathname,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        dispatch(getToken(result.token));
        
        identify(ID_SIGNUP_METHOD, 'google');
        identify(ID_USER_ID, user?.id);
        identify(ID_INTEREST_GROUP_EVENT_ORIGIN, slug);
        
        logEvent(PLAYER_SIGNUP, {
          funnel_step: 'tutorial_0',
          signup_method: 'google',
          user_id: user?.id,
          event_origin: slug
        });

        // Push the event to dataLayer
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ 'event': 'Signup' });

        setIsOpenLogin?.(false);
        if (rewardPath) navigate({ pathname: `/events/${slug}`, search: '?from=result' });
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const facebookResponse = (response) => {
    fetch(
      baseUrl + `/auth/facebook/callback?provider=facebook&access_token=${response.accessToken}&location=${document.location.pathname}`,
      {
        method: 'GET',
      }
    )
    .then(response => response.json())
    .then(result => {
      dispatch(getToken(result.token));
        
      identify(ID_SIGNUP_METHOD, 'facebook');      
      identify(ID_USER_ID, user?.id);
      identify(ID_INTEREST_GROUP_EVENT_ORIGIN, slug);
  
      logEvent(PLAYER_SIGNUP, {
        funnel_step: 'tutorial_0',
        signup_method: 'facebook',
        user_id: user?.id,
        event_origin: slug
      });
      
      // Push the event to dataLayer
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ 'event': 'Signup' });
      
      setIsOpenLogin?.(false);
    })
    .catch(error => {
      console.warn('Facebook login error', error);
    });
  };
  

  const handleOnClickGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleGoogleSuccess(codeResponse.access_token);
    },
  });

  const handleOnClickTwitter = () => {
    const callbackUrl = document.location.href;

    api
      .createAuthCallback({
        params: {
          method: 'twitter',
          callback_url: callbackUrl,
        },
      })
      .then((response: any) => {
        const callbackUrl = response?.data?.callback_url;

        if (callbackUrl) {
          window.location.replace(`${callbackUrl}`);
        }
      });
  };

  const handleOnClickTikTok = () => {
    const callbackUrl = document.location.href;

    api
      .createTikTokAuthCallback({
        params: {
          callback_url: callbackUrl,
        },
      })
      .then((response: any) => {
        const callbackUrl = response?.data?.callback_url;
        if (callbackUrl) {
          window.location.replace(`${callbackUrl}`);
        }
      });
  };

  return {
    facebookResponse,
    handleOnClickGoogle,
    handleOnClickTwitter,
    handleOnClickTikTok,
  };
};
