export const AMPLITUDE_API_KEY = 'fe40de3a49c7935777cba331d316d65e';
export const SPLASHSCREEN_SHOWN = 'splashscreen_shown';
export const EVENT_PAGE_SHOWN = 'event_page_shown';
export const TUTORIAL_STEP_1_SHOWN = 'tutorial_step_1_shown';
export const TUTORIAL_STEP_1_CLICKED = 'tutorial_step_1_clicked';
export const CHALLENGE_CLICKED = 'challenge_clicked';
export const WAGER_VIEW_SHOWN = 'wager_view_shown';
export const WAGER_VIEW_CLICKED = 'wager_view_clicked';
export const WAGER_VIEW_GOBACK_CLICKED = 'wager_view_goback_clicked';
export const ROUND_TRANSITION_3_SHOWN = 'round_transition_3_shown';
export const ROUND_TRANSITION_2_SHOWN = 'round_transition_2_shown';
export const ROUND_TRANSITION_1_SHOWN = 'round_transition_1_shown';
export const ROUND_TRANSITION_0_SHOWN = 'round_transition_0_shown';
export const QUESTION_SHOWN = 'question_shown';
export const QUESTION_ATTEMPTED = 'question_attempted';
export const RESULTS_SHOWN = 'results_shown';
export const CLAIM_REWARD_CLICKED = 'claim_reward_clicked';
export const LOGIN_OPTIONS_SHOWN = 'login_options_shown';
export const PLAYER_SIGNUP = 'player_signup';
export const TUTORIAL_STEP_2_SHOWN = 'tutorial_step_2_shown';
export const TUTORIAL_STEP_2_CLICKED = 'tutorial_step_2_clicked';
export const PLAYER_PROFILE_SHOWN = 'player_profile_shown';
export const PLAYER_PROFILE_HIDDEN = 'player_profile_hidden';
export const DEPOSIT_BUTTON_CLICKED = 'deposit_button_clicked';
export const DEPOSIT_SCREEN_SHOWN = 'deposit_screen_shown';
export const DEPOSIT_SCREEN_GO_BACK = 'deposit_screen_go_back';
export const DEPOSIT_SCREEN_PAYMENT_OPTION_SELECTED = 'deposit_screen_payment_option_selected';
export const DEPOSIT_PROCESSING_SHOWN = 'deposit_processing_shown';
export const DEPOSIT_PROCESSING_FAILED_SHOWN = 'deposit_processing_failed_shown';
export const LEADERBOARD_SHOWN = 'leaderboard_shown';
export const WAGER_VIEW_CONFIRM_BUTTON_CLICKED = 'wager_view_confirm_button_clicked';
export const SEEALL_BUTTON_CLICKED = 'seeall_button_clicked';
export const OTHERS_PROFILE_VIEW_CLICKED = 'others_profile_view_clicked';
export const WITHDRAW_BUTTON_CLICKED = 'withdraw_button_clicked';

export const ID_SIGNUP_METHOD = 'signup_method';
export const ID_USER_ID = 'user_id';
export const ID_CHALLENGES_PLAYED = 'challenges_played';
export const THREE_OR_MORE_CHALLENGES_PLAYED = 'three_or_more_challenges_played';
export const ID_MONEY_BALANCE = 'money_balance';
export const ID_CHALLENGES_WON = 'challenges_won';
export const ID_CHALLENGES_LOST = 'challenges_lost';
export const ID_USER_EMAIL = 'email';
export const ID_INTEREST_GROUP_EVENT_ORIGIN = 'interest_group_event_origin';
export const ID_SCREEN_WIDTH = 'screen_width';
export const ID_SCREEN_HEIGHT = 'screen_height';
