export const GET_TRIVIA = '@get/trivia';
export const SET_ROUND = '@set/round';
export const GET_OPPO_INFO = '@get/opponentInfo';
export const RESET_TRIVIA = '@set/reset_trivia';

export const getTrivia = (payload) => ({
  type: GET_TRIVIA,
  payload,
});

export const setRound = () => ({ type: SET_ROUND });

export const getOpponentInfo = () => ({ type: GET_OPPO_INFO });

export const resetTrivia = () => ({ type: RESET_TRIVIA });
