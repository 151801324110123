import { motion } from 'framer-motion';
import React from 'react';
import UserImage from '../UserImage';

interface IAnsweredInfo {
  userImage;
  time;
  show;
  right?: boolean;
}

const AnsweredInfo = (props: IAnsweredInfo) => {
  const { userImage, time, show, right } = props;

  if (!show) return;

  const formattedTime = (time || 0.0).toFixed(1);

  return (
    <motion.div
      initial={right ? { x: 100, opacity: 0.1 } : { opacity: 0.1, left: -100 }}
      animate={right ? { x: 0, opacity: 1 } : { opacity: 1, left: -20 }}
      transition={{ duration: 0.6 }}
      className={`absolute -top-3 se:-top-4 ${right ? '-right-6 se:-right-7' : '-left-6 se:-left-5'
        }`}
    >
      <div className="relative w-12 h-7 bg-sky-500 text-center font-bold rounded mx-auto">
        <span className="text-primary align-sub mb-2 p-2">{formattedTime}s</span>
        <div className="border border-solid border-t-sky-500 border-t-8 border-x-transparent border-x-8 border-b-0 absolute -bottom-1 left-1/2 transform -translate-x-1/2" />
      </div>
      <UserImage userImage={userImage} />
    </motion.div>
  );
};

export default AnsweredInfo;
