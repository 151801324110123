import React, { useEffect, useState } from 'react';
import Round from '../Round';
import ScoreDisplay from '../ScoreDisplay';
import TimerCountDown from '../TimerCountDown';
import { IAnswer, ITrivia, IUser } from '../../../../../types/api';
import { ENABLE_TIMER_COUNTDOWN_TO_RUN_MS, TOTAL_ROUNDS } from '../../../../_shared/constants';
import { IAppData } from '../../../../app-context';

interface IPlayHeader {
  trivia: ITrivia;
  answerSelected: {
    answer: IAnswer | null;
    answeredIn: number | null;
    opponentAnswer: IAnswer | null;
    userPickedFirst: boolean;
    startRound: boolean;
  };
  userData: IUser & IAppData;
  userRoundScore: number;
  round: number;
}

const PlayHeader = (props: IPlayHeader) => {
  const { trivia, userData, answerSelected, userRoundScore, round } = props;
  const { answeredIn, opponentAnswer, answer } = answerSelected;
  const [initCountdown, setInitCountdown] = useState(false);
  const [storedRound] = useState(round);

  useEffect(() => {
    const initTimeout = setTimeout(() => setInitCountdown(true), ENABLE_TIMER_COUNTDOWN_TO_RUN_MS);
    return () => clearTimeout(initTimeout);
  });

  return (
    <div className="items-center w-full max-w-sm px-3 sm:px-0">
      <div className="grid grid-cols-3 gap-x-4">
        <ScoreDisplay
          user={userData}
          userAnsweredIn={answeredIn}
          userRoundScore={userRoundScore}
          userAnswer={answer}
          opponent={trivia?.opponent}
          opponentRound={trivia?.opponent_round}
        />
        <TimerCountDown initCountdown={initCountdown} />
        <Round
          userTotalScore={userData?.total_score}
          userAnsweredIn={answeredIn}
          opponentAnswer={opponentAnswer}
          currentRound={round}
          opponent={trivia?.opponent}
          opponentRound={trivia?.opponent_round}
        />
      </div>
      <div className="text-center text-gray-500 font-bold">
        Round {storedRound}/{TOTAL_ROUNDS}
      </div>
    </div>
  );
};

export default PlayHeader;
