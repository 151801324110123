import React from 'react';

import Matching from './PvpMatching';
import Page from '../../../components/organisms/Page';
import usePageMeta from '../../../hooks/usePageMeta';

function PvpMatchWrapper() {
  usePageMeta({});
  return (
    <Page className="bg-primary">
      <Matching />
    </Page>
  );
}

export default PvpMatchWrapper;
