import React, { createContext, ReactNode, useEffect } from 'react';
import { IUser } from '../../types/api';
import { useAppSelector } from '../redux/store';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { identify, logEvent } from '../analytics/amplitude';
import useFeatures from '../hooks/useFeatures';
import { VARIANT_A_FLAG_KEY, VARIANT_B_FLAG_KEY } from '../_shared/constants';
import { THREE_OR_MORE_CHALLENGES_PLAYED } from '../analytics/types';

interface IAppProvider {
  children: ReactNode;
}

export interface IAppData {
  round: number;
  user_score: number;
  challengerIds: Array<string | number>;
  game_played: number;
  benefits: {
    trophies: number;
    rewards: number;
  };
}

interface IAppContext {
  userData: { user: IUser; token: string | null };
  handleLogOut: () => void;
  appData: IAppData;
}

export const AppContext = createContext<IAppContext>({
  userData: null,
  handleLogOut: () => {},
  appData: {
    round: 0,
    user_score: 0,
    challengerIds: [],
    game_played: 0,
    benefits: {
      trophies: 0,
      rewards: 0,
    },
  },
});

export const AppProvider = (props: IAppProvider) => {
  const { children } = props;
  const { variantAFlag, variantBFlag } = useFeatures({});
  const { trivia, user } = useAppSelector((state) => ({
    trivia: state.trivia,
    user: state.user,
  }));

  const appData = { ...omit(trivia, ['trivia']), ...omit(user, ['user', 'token']) };

  useEffect(() => {
    if (appData.game_played >= 3) {
      logEvent(THREE_OR_MORE_CHALLENGES_PLAYED, {
        event_category: 'sports',
        event_topic: 'basketball',
      });
    }
  }, [appData.game_played]);

  useEffect(() => {
    identify(VARIANT_A_FLAG_KEY, variantAFlag);
    identify(VARIANT_B_FLAG_KEY, variantBFlag);
  }, [variantAFlag, variantBFlag]);

  const values = {
    userData: pick(user, ['user', 'token']),
    handleLogOut: () => {},
    appData,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};
