import React from 'react';
import NotFound from '../../components/NotFound';
import Page from '../../components/_shared/Page';

function NotFoundWrapper() {
  return (
    <Page className="bg-primary">
      <NotFound />
    </Page>
  );
}

export default NotFoundWrapper;
