import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { CookiesProvider } from 'react-cookie';
import { UserProvider } from './contexts/UserContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import './analytics/firebase';
import { wager_router } from './wager/routes';
import { RouterProvider } from 'react-router-dom';
import { SheetProvider } from './contexts/SheetContext/SheetContext';
import { OrientationProvider } from './contexts/OrientationContext/OrientationContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toaster } from 'react-hot-toast';
// import { worker } from './wager/mocks/browser';
import { initAmplitude } from './wager/analytics/amplitude';
import { AppProvider } from './wager/app-context';
import { server } from './wager/mocks/handlers';
import { Provider } from 'react-redux';
import { persistor, store } from './wager/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';
import { ConfigCatProvider, createConsoleLogger, LogLevel, PollingMode } from 'configcat-react';
import { AMPLITUDE_API_KEY } from './wager/analytics/types';

server;

export const queryClient = new QueryClient();

initAmplitude(AMPLITUDE_API_KEY);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const tagManagerArgs = {
  gtmId: 'GTM-54VFMDP',
};

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  TagManager.initialize(tagManagerArgs);
}
/**To purge redux persist**/
// persistor.flush().then(() => {
//   return persistor.purge();
// });

const logger = createConsoleLogger(LogLevel.Info);
root.render(
  <React.StrictMode>
    <ConfigCatProvider
      sdkKey={process.env.REACT_APP_CONFIG_CAT}
      options={{ logger }}
      pollingMode={PollingMode.LazyLoad}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <QueryClientProvider client={queryClient}>
              <CookiesProvider>
                <UserProvider>
                  <SheetProvider>
                    <OrientationProvider>
                      <AppProvider>
                        <Toaster />
                        {/*<RouterProvider router={router} />*/}
                        <RouterProvider router={wager_router} />
                      </AppProvider>
                    </OrientationProvider>
                  </SheetProvider>
                </UserProvider>
              </CookiesProvider>
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </ConfigCatProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
