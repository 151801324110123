import React, { useState, useEffect } from 'react';

const LoadingSpinner = ({ show }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, 400);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center transition-opacity duration-400 ${
        visible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{ backgroundColor: 'rgba(24, 44, 130, 0.1)' }}
    >
      <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin" />
    </div>
  );
};

export default LoadingSpinner;
