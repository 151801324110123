import React, { useContext, useEffect, useState } from 'react';
import Reward from '../../components/Reward';
import Page from '../../components/_shared/Page';
import { AppContext } from '../../app-context';
import LoginModal from '../../components/LoginModal';
import pick from 'lodash/pick';
import { useChallenge } from '../../hooks/challenge';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/_shared/LoadingSpinner';
import { isEmpty } from 'lodash';
import usePageMeta from '../../../hooks/usePageMeta';
import { RESULTS_SHOWN } from '../../analytics/types';
import { logEvent } from '../../analytics/amplitude';
import { useAppDispatch } from '../../redux/store';
import { setBenefits } from '../../redux/actions/user';

function RewardWrapper() {
  const { userData, appData } = useContext(AppContext);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const { challengeId } = useParams();
  const { challenge } = useChallenge({ challengeId: +challengeId });
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const isClaim = queryParams.get('claim');

  const doesTheUserWon = appData?.user_score > challenge?.opponentTotalScore;
  const wasADraw = appData?.user_score === challenge?.opponentTotalScore;
  let funnel_step = 'regular';
  if (['0', '1'].indexOf(challengeId) >= 0) {
    funnel_step = `tutorial_${challengeId}`;
  }

  useEffect(() => {
    logEvent(RESULTS_SHOWN, {
      funnel_step,
      challenge_number: challengeId,
      user_id: userData?.user?.id,
      result: doesTheUserWon ? 'won' : 'lost',
      game_mode: 'fwpvp',
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(challenge) && isClaim) {
      dispatch(setBenefits({ doesTheUserWon, wasADraw, reward: +challenge?.challenge_reward }));
    }
  }, [challenge && Object.keys(challenge).length]);

  useEffect(() => {
    const initTimer =
      isClaim &&
      setTimeout(() => {
        navigate({ pathname: location.pathname, search: '' });
      }, 1000);
    return () => clearTimeout(initTimer);
  }, [challenge && Object.keys(challenge).length]);

  usePageMeta({ themeColor: '#000000' });

  return (
    <Page className="bg-black" hasOpacityTransition>
      {!isEmpty(challenge) ? (
        <>
          <Reward
            user={userData?.user}
            appData={appData}
            challengePoint={challenge.challenge_reward}
            challengeScore={challenge?.opponentTotalScore}
            challenger={challenge.challenger}
            setIsOpenLogin={setIsOpenLogin}
          />
          <LoginModal
            isOpen={isOpenLogin}
            setIsOpenLogin={setIsOpenLogin}
            userInfo={{ ...pick(appData, ['benefits']) }}
          />
        </>
      ) : (
        <LoadingSpinner show />
      )}
    </Page>
  );
}

export default RewardWrapper;
