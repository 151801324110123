import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../hooks/user';
import { ChallengerCard } from './components';
import useMatching from './hooks';
import currencyDollarImage from './../../assets/images/currency_dollar.png';
import basketballImage from './../../assets/images/basketball.png';
import Xcircle from './../../../assets/svgs/XCircle.svg';
import {
  WAGER_VIEW_CONFIRM_BUTTON_CLICKED,
  WAGER_VIEW_GOBACK_CLICKED,
  WAGER_VIEW_SHOWN,
} from '../../analytics/types';
import { logEvent } from '../../analytics/amplitude';
import { AppContext } from '../../app-context';

export default function Matching() {
  const { user } = useUser();
  const { slug, challenge_id } = useParams();
  const navigate = useNavigate();
  const { opponent, challenge, startChallenge } = useMatching({ slug, challenge_id });
  const [notEnoughCash, setNotEnoughCash] = useState(false);
  const { appData } = useContext(AppContext);
  const categoryTitle = challenge?.event?.category?.title;
  const subCategoryTitle = challenge?.event?.subCategory?.title;

  let funnel_step = 'regular';

  if ([0, 1].indexOf(challenge?.id) >= 0) {
    funnel_step = `tutorial_${challenge?.id}`;
  }

  useEffect(() => {
    if (challenge) {
      logEvent(WAGER_VIEW_SHOWN, {
        funnel_step,
        game_mode: 'fwpvp',
        wager_amount: challenge.challenge_entry_fee,
        user_id: user?.id,
      });
    }
  }, [challenge]);

  useEffect(() => {
    if (challenge) {
      const challengeEntryFee = Number.parseFloat(`${challenge.challenge_entry_fee}`);

      if (challengeEntryFee > 0) {
        const userCurrentBalance = Number.parseFloat(`${appData?.benefits?.rewards}`);
        const notEnought = userCurrentBalance < challengeEntryFee;
        const notANumber = Number.isNaN(userCurrentBalance);

        setNotEnoughCash(notEnought || notANumber);
      }
    }
  }, [challenge, user]);

  const handleOnPlay = () => {
    if (startChallenge) {
      logEvent(WAGER_VIEW_CONFIRM_BUTTON_CLICKED, {
        funnel_step,
        game_mode: 'fwpvp',
        wager_amount: challenge.challenge_entry_fee,
        user_id: user?.id,
        player_wager_balance: notEnoughCash ? 'not sufficient' : 'sufficient',
      });

      if (notEnoughCash) return;

      navigate(`/events/${slug}/challenges/${challenge?.id}/setup`);
    }
  };

  const handleOnBack = () => {
    logEvent(WAGER_VIEW_GOBACK_CLICKED, {
      funnel_step,
      game_mode: 'fwpvp',
      wager_amount: challenge.challenge_entry_fee,
      user_id: user?.id,
    });

    navigate(-1);
  };

  return (
    <div className="w-full text-center flex flex-col py-10 se-lg:py-4 se:py-2 relative">
      <div className="w-16 mx-auto">
        <img src={basketballImage} />
      </div>
      <div className={'top-[5%] absolute right-4 sm:right-10 sm:top-[6%] cursor-pointer'}>
        <img src={Xcircle} alt={''} onClick={handleOnBack} />
      </div>

      <p className="font-bold text-gray-400 mt-2 se-lg:text-lg se:text-base">Quiz topic</p>

      {categoryTitle && (
        <div className="text-white text-3xl font-bold space-x-2 text-center se-lg:text-3xl se:text-2xl">
          <span>{categoryTitle}</span>
          <span>x</span>
          <span>{subCategoryTitle}</span>
        </div>
      )}

      <div className="flex flex-row justify-center mt-6 relative se-lg:mt-6 se:mt-2">
        <div className="">
          <ChallengerCard user={user} />

          <p className="text-indigo-200 font-bold text-lg mt-2">Wager amount</p>
          <div className="text-white">
            <div className="flex items-center justify-center">
              <img src={currencyDollarImage} className="w-6 h-6" />
              <span className="font-bold">{challenge?.challenge_entry_fee}</span>
            </div>
          </div>
        </div>
        <div className="mx-2" />

        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/3 z-10 text-white font-bold text-lg bg-indigo-1100 p-2 rounded-full justify-center self-center">
          VS
        </span>
        <div className="">
          <ChallengerCard user={opponent} isOpponent={true} />

          <p className="text-indigo-200 font-bold text-lg mt-2">Wager amount</p>
          <div className="text-white">
            <div className="flex items-center justify-center">
              <img src={currencyDollarImage} className="w-6 h-6" />
              <span className="font-bold">{challenge?.challenge_entry_fee}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col justify-center">
        <p className="text-indigo-200 font-bold text-xl se-lg:text-lg se:text-lg">Prize Pool</p>
        <p className="text-white font-bold text-5xl se:text-4xl">${challenge?.challenge_reward}</p>
      </div>

      <div className="flex flex-1 px-4">
        <div
          onClick={handleOnPlay}
          className={`mt-auto mx-2 flex-1 flex justify-center items-center h-14 text-white font-bold rounded-xl cursor-pointer ${notEnoughCash ? 'bg-dark-purple-500' : 'bg-pink-500 active:bg-pink-600'
            } transition duration-300 ease-in-out hover:opacity-90 se:h-12 `}
        >
          <p className={`text-xl text-gray-50`}>
            {notEnoughCash ? 'Insufficient Balance' : 'Confirm & Start'}
          </p>
        </div>
      </div>
    </div>
  );
}
