import { IEvent } from '../../../types/api';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useQuery } from 'react-query';
import api from '../../../config/api';
import { getTopPayers } from '../../redux/actions/top-players';

interface IUseTopPlayersReturn {
  topPlayers: { data: IEvent; status: string };
}

export const useTopPlayers = ({
  params,
}: {
  params: Record<string, any>;
}): IUseTopPlayersReturn => {
  const dispatch = useAppDispatch();
  const { topPlayers } = useAppSelector((state) => state.topPlayers);

  useQuery(
    ['rankingLogs', params.event_id, params.event_slug, params.challenge_id],
    async () => {
      const response = await api.fetchRankingLogs({ params });
      dispatch(getTopPayers(response));
      return response.data;
    },
    {
      enabled: !!params.event_id || !!params.event_slug || !!params.challenge_id,
    }
  );

  return {
    topPlayers,
  };
};
