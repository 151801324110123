import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import appReducer from './reducers';
import type { Action } from './types';

// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const rootReducer = (state: ReturnType<typeof persistedReducer> | undefined, action: Action) => {
  // if (action.type === logout.type) {
  //   // storage.removeItem('persist:auth');
  //   return persistedReducer(undefined, action);
  // }
  return persistedReducer(state, action);
};

const middleware = [];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}
let composeEnhancers = compose;
if (typeof window !== 'undefined') {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const enhancers = composeEnhancers(applyMiddleware(...middleware));

export const store = createStore(rootReducer, enhancers);
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
