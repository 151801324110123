import { motion } from 'framer-motion';
import blankProfileImage from '../../../../../assets/images/blank_profile_picture.png';
import currencyDollarImage from '../../../../assets/images/currency_dollar.png';
import whiteTrophyImage from '../../../../assets/images/white_trophy.png';
import React, { Dispatch, forwardRef, LegacyRef, SetStateAction } from 'react';
import { IUser } from '../../../../../types/api';
import { IAppData } from '../../../../app-context';

interface IWalletSummary {
  setIsOpenLogin: Dispatch<SetStateAction<boolean>>;
  user: IUser;
  userInfo: Partial<IAppData>;
}
const WalletSummary = forwardRef((props: IWalletSummary, ref: LegacyRef<HTMLDivElement>) => {
  const { setIsOpenLogin, user, userInfo } = props;

  const onError = (e) => {
    // Replace the src of the img tag with the fallback image
    e.target.src = blankProfileImage;
  };

  return (
    <div
      className="py-[8px] mx-4 px-4 sticky top-[11px] flex h-[40px] bg-black rounded-full mt-[11px] items-center text-white space-x-2 z-50"
      ref={ref}
    >
      <motion.img
        className="w-8 h-8 object-cover rounded-full bg-white hover:cursor-pointer"
        src={user?.profile_image_url ?? blankProfileImage}
        onClick={() => {
          if (user) setIsOpenLogin((prev) => !prev);
        }}
        onError={onError}
      />
      <p className="flex flex-1 font-bold truncate ellipisis">{user?.display_name ?? '---'}</p>
      <div className="flex text-white font-bold w-48 justify-around">
        <div className="flex align-center">
          <img src={currencyDollarImage} className="w-6 h-6" />{' '}
          <span>{(userInfo?.benefits?.rewards ?? 0).toFixed(2)}</span>
        </div>
        <div className="flex align-center">
          <img src={whiteTrophyImage} className="w-6 h-6" />{' '}
          <span>{userInfo?.benefits?.trophies || '0'}</span>
        </div>
      </div>
    </div>
  );
});

export default WalletSummary;
