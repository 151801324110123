import React from 'react';
import { isMobile } from 'react-device-detect';

const Question = ({ title }: { title: string }) => {
  const length = title?.length;
  const isSmallerThan60 = length <= 60 && isMobile;
  const isBetween60And90 = length > 60 && length <= 90 && isMobile;
  return (
    <div
      className={`text-white font-semibold text-center text-vw-2xl sm:text-2xl xl:text-4xl ${
        isMobile && 'se:text-vw-xl'
      } px-3 sm:px-0 flex-1 flex items-center justify-center select-none ${
        isSmallerThan60 && 'text-vw-4xl se:text-vw-3xl'
      } ${isBetween60And90 && 'text-vw-2xl se:text-vw-2xl'}`}
    >
      <span>{title}</span>
    </div>
  );
};

export default Question;
