import React, { memo, useEffect, useState } from 'react';
import { IAnswer, ITrivia, IUser } from '../../../../../types/api';
import Answer from '../Answer';
import Question from '../Question';
import AnsweredInfo from '../AnswerInfo';
import { motion } from 'framer-motion';
import { logEvent } from '../../../../analytics/amplitude';
import { QUESTION_SHOWN } from '../../../../analytics/types';
import {
  DURATION_OF_THE_RESPONSE_ANIMATION_SECONDS,
  DELAY_OF_THE_RESPONSE_ANIMATION_SECONDS,
} from '../../../../_shared/constants';

interface IPlayBody {
  trivia: ITrivia;
  handleAnswerClick: (answer: IAnswer) => void;
  answerSelected: {
    answer: IAnswer | null;
    answeredIn: number | null;
    opponentAnswer: IAnswer | null;
    userPickedFirst: boolean;
    startRound: boolean;
  };
  userImage: string;
  challengeId: string;
  userData: IUser;
}

const PlayBody = memo(
  (props: IPlayBody) => {
    const { trivia, handleAnswerClick, answerSelected, userImage, challengeId, userData } = props;
    const { answer, opponentAnswer, userPickedFirst, answeredIn } = answerSelected;
    const [isClickable, setIsClickable] = useState(false);

    let funnel_step = 'regular';

    if (['0', '1'].indexOf(challengeId) >= 0) {
      funnel_step = `tutorial_${challengeId}`;
    }

    useEffect(() => {
      logEvent(QUESTION_SHOWN, {
        funnel_step,
        challenge_number: challengeId,
        user_id: userData?.id,
        round_number: trivia?.currentRound,
        game_mode: 'fwpvp',
      });
    }, []);

    const handleBg = (title, correct) => {
      const isPicked = title === answer?.title;
      const isOpponentPicked = title === opponentAnswer?.title;
      const userWrong = answer && isPicked && !correct;
      const userRight = answer && isPicked && correct;
      const oppoWrong = opponentAnswer && isOpponentPicked && !opponentAnswer.correct;
      const oppoRight = opponentAnswer && isOpponentPicked && opponentAnswer.correct;
      return userWrong || (userPickedFirst && oppoWrong)
        ? 'bg-red-500 text-white'
        : userRight || (userPickedFirst && oppoRight) || (answer && correct)
          ? 'bg-green-500 text-white'
          : 'bg-gray-300 text-gray-700';
    };

    return (
      <div className="flex flex-1 flex-col w-full max-w-sm justify-around ">
        <Question title={trivia?.round?.question as unknown as string} />
        <div className={`space-y-4 flex flex-col justify-center px-8 sm:px-0`}>
          {trivia?.round?.answers?.map(({ title, correct }: IAnswer, idx) => {
            return (
              <motion.div
                key={idx}
                className="relative"
                animate={{ opacity: 1 }}
                transition={{
                  duration: DURATION_OF_THE_RESPONSE_ANIMATION_SECONDS,
                  delay: DELAY_OF_THE_RESPONSE_ANIMATION_SECONDS,
                }}
                initial={{ opacity: 0 }}
                onAnimationComplete={() => {
                  // If the last answer finished the animation, then is clickable
                  if (idx + 1 === trivia?.round?.answers.length) {
                    setIsClickable(true)
                  }
                }}
              >
                <Answer
                  selectedAnswer={answer}
                  title={title}
                  onClick={() => {
                    if (isClickable) {
                      handleAnswerClick({ title, correct } as IAnswer)
                    }
                  }}
                  isClickable={isClickable}
                  background={handleBg(title, correct)}
                />
                <AnsweredInfo
                  time={answeredIn}
                  userImage={userImage}
                  show={title === answerSelected?.answer?.title}
                />
                <AnsweredInfo
                  right
                  time={trivia?.opponent_round?.answered_in}
                  userImage={trivia?.opponent?.profile_image_url}
                  show={title === answerSelected?.opponentAnswer?.title}
                />
              </motion.div>
            );
          })}
        </div>
      </div>
    );
  },
  (prev, next) => JSON.stringify(prev.answerSelected) === JSON.stringify(next.answerSelected)
);

PlayBody.displayName = 'PlayBody';

export default PlayBody;
