import blankProfileImage from '../../../../../assets/images/blank_profile_picture.png';
import trophyImage from '../../../../assets/images/trophy.png';
import React, { Dispatch, forwardRef, LegacyRef, SetStateAction } from 'react';
import { IWagerChallenge } from '../../../../../types/api';
import UserImage from '../../../_shared/UserImage';
import { Ivariants } from '../../../../hooks/useFeatures';
interface IChallengeCard {
  challenge: IWagerChallenge | any;
  gamePlayed: number;
  setIsSpotlightOpen: Dispatch<SetStateAction<boolean>>;
  handleOnClickChallenge: (challenge: string) => void;
  variant: Ivariants;
}

const ChallengeCard = forwardRef((props: IChallengeCard, ref: LegacyRef<HTMLDivElement>) => {
  const { challenge, gamePlayed, setIsSpotlightOpen, handleOnClickChallenge, variant } = props;

  return (
    <div
      className="flex w-full rounded-lg mb-1.5 h-[70px] items-center z-10 spotLight-click"
      style={{
        background: 'rgba(235, 250, 255, 0.80)',
      }}
      ref={ref}
    >
      <div className="flex flex-col justify-center ml-[14px] mr-[16px] items-center">
        <UserImage
          width={31}
          height={31}
          player={challenge.challenger}
          blankProfileImage={blankProfileImage}
        />
        <div className="flex flex-row justify-center items-center h-5">
          <img className="w-[15px] h-[15px]" src={trophyImage} />
          <span className="ml-[2px] text-[12px] text-indigo-900 font-semibold">
            {challenge.challenger?.statistic?.score}
          </span>
        </div>
      </div>
      <div className="text-indigo-900 w-40 truncate">
        <p className="font-bold truncate">
          Win ${challenge.challenge_reward} [{challenge.challenge_skill}]
        </p>
        <p className="truncate">vs. {challenge.challenger?.display_name} </p>
      </div>
      <div
        onClick={
          variant.spotlight && gamePlayed <= 0
            ? () => setIsSpotlightOpen(true)
            : () => handleOnClickChallenge(challenge?.id)
        }
        className="flex truncate w-[90px] bg-pink-500 text-white font-bold rounded-[19.5px] justify-center ml-auto cursor-pointer mr-[15px] custom-shadow-play-button h-[25px] items-center"
      >
        <span className="text-[14px]">Play ${challenge.challenge_entry_fee}</span>
      </div>
    </div>
  );
});

ChallengeCard.displayName = 'ChallengeCard';

export default ChallengeCard;
