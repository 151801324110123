import { combineReducers } from 'redux';
import user from './user';
import challenges from './challenges';
import trivia from './trivia';
import events from './events';
import topPlayers from './top-players';
import variants from './variants';

export default combineReducers({
  user,
  challenges,
  trivia,
  events,
  topPlayers,
  variants,
});
