import { useQuery } from "react-query";

import api from "./../../config/api";

export default function handler({params}) {
  const response = useQuery(['rankingLogs', params.event_id, params.event_slug, params.challenge_id], async () => {
    const response = await api.fetchRankingLogs({params});
    return response.data
  }, {
    enabled: !!params.event_id || !!params.event_slug || !!params.challenge_id
  })
  
  return response;
}