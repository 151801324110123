
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import LoginSheet from './../../components/organisms/LoginSheet/LoginSheet';
import UserStatisticsSheet from './../../components/organisms/UserStatisticsSheet/UserStatisticsSheet';

export type WithChildren = {
  children: ReactNode;
};

type Sheets = 'none' | 'login' | 'user-statistics';

interface SheetContextValueSafe {
  setSheet: (sheet: Sheets) => void;
  setData: (data: any) => void;
}

const SheetContext = createContext<SheetContextValueSafe>({
  setSheet: (sheet: 'none') => null,
  setData: (data: any) => null,
});

export const SheetProvider = ({ children }: WithChildren) => {
  const [sheet, setSheet] = useState<Sheets>('none');
  const [data, setData] = useState<any>();

  const value: SheetContextValueSafe = useMemo(
    () => ({
      setSheet,
      setData,
      sheet
    }),
    [setSheet, setData, sheet]
  );

  return (
    <SheetContext.Provider value={value}>
      {children}
      <LoginSheet isOpen={sheet === 'login'} onClose={() => setSheet('none')} />
      <UserStatisticsSheet data={data} isOpen={sheet === 'user-statistics'} onClose={() => setSheet('none')} />
    </SheetContext.Provider>
  );
};

export const useSheet = () => useContext(SheetContext);
