import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { IUser } from '../../../../types/api';

const UserImage = ({ player, blankProfileImage, width, height }: {
  player?: IUser;
  blankProfileImage?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <LazyLoadImage
      alt={player?.display_name}
      height={height || 44}
      width={width || 44}
      effect="blur"
      src={player?.profile_image_url ? player?.profile_image_url : blankProfileImage}
      style={{
        borderRadius: 80,
        clipPath: 'polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)',
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      referrerPolicy="no-referrer"
    />
  );
};

export default UserImage;
