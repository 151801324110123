import React, { Dispatch, SetStateAction } from 'react';
import usePageMeta from '../../../hooks/usePageMeta';
import fireworksGif from '../../../assets/gifs/fireworks.gif';
import MatchResultWithOpponent from './lib/MatchRewardWithOpponent';
import { IAppData } from '../../app-context';
import { IOpponent, IUser } from '../../../types/api';

interface IReward {
  user: IUser;
  appData: IAppData;
  setIsOpenLogin: Dispatch<SetStateAction<boolean>>;
  challengePoint: string;
  challengeScore: number;
  challenger: IOpponent;
}
const Reward = (props: IReward) => {
  const { user, appData, setIsOpenLogin, challengePoint, challengeScore, challenger } = props;
  usePageMeta({ themeColor: '#000000' });

  return (
    <div className="w-full px-3 overflow-x-hidden flex flex-col relative">
      <MatchResultWithOpponent
        user={user}
        userIsWinner={appData?.user_score > challengeScore}
        isDraw={appData?.user_score === challengeScore}
        opponent={challenger}
        points={+challengePoint}
        setIsOpenLogin={setIsOpenLogin}
      />
      <img src={fireworksGif} className="absolute top-0 left-0 opacity-30 -z-2" />
      <img src={fireworksGif} className="absolute bottom-0 right-0 opacity-30 -z-2" />
    </div>
  );
};

export default Reward;
