import { Action } from '../../types';
import { IWagerChallenge } from '../../../../types/api';
import { GET_CHALLENGE, GET_CHALLENGES } from '../../actions/challenges';

export interface IChallengesState {
  challenges: IWagerChallenge[];
  challenge: IWagerChallenge;
}

const defaultState: IChallengesState = {
  challenges: null,
  challenge: null,
};

const challengeReducer = (state = defaultState, { type, payload }: Action) => {
  switch (type) {
    case GET_CHALLENGES: {
      return {
        ...state,
        challenges: payload,
      };
    }
    case GET_CHALLENGE: {
      return {
        ...state,
        challenge: payload,
      };
    }
    default:
      return state;
  }
};

export default challengeReducer;
