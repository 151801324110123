import React from 'react';
import { IAnswer as IAnswerType } from '../../../../../types/api';

interface IAnswer {
  onClick: () => void;
  title: string;
  selectedAnswer: IAnswerType;
  background: string;
  isClickable?: boolean;
}

const Answer = (props: IAnswer) => {
  const { onClick, title, selectedAnswer, background, isClickable } = props;

  return (
    <div
      className={`flex items-center justify-center h-16 se:h-15 px-2 truncate text-ellipsis w-full 
      font-bold text-center rounded-lg text-xl ${isClickable ? 'cursor-pointer' : 'cursor-default'} ${background} ${selectedAnswer && 'pointer-events-none'
        }`}
      onClick={onClick}
    >
      <span>{title}</span>
    </div>
  );
};

export default Answer;
