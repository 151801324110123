import gmail from '../../../../assets/images/google_logo_g.png';
import FacebookLogin from '@greatsumini/react-facebook-login';
import fb from '../../../../../assets/images/fb_logo.png';
import trophy from '../../../../../assets/svgs/Trophy.svg';
import currency from '../../../../../assets/svgs/CurrencyCircleDollar.svg';
import React, { Dispatch, SetStateAction } from 'react';
import { IAppData } from '../../../../app-context';

interface ILogin {
  loginProviders: {
    facebookResponse: (response) => void;
    handleOnClickGoogle: () => void;
    handleOnClickTwitter: () => void;
    handleOnClickTikTok: () => void;
  };
  userInfo: Partial<IAppData>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Login = (props: ILogin) => {
  const { loginProviders, userInfo, setOpen } = props;
  const { facebookResponse, handleOnClickGoogle } = loginProviders ?? {};

  return (
    <div
      className="flex flex-col px-8 pt-[21px] pb-2 h-96 justify-between items-center"
      onClick={() => setOpen(false)}
    >
      <div>
        <p className="font-bold text-[24px] text-indigo-1000 text-center truncate">
          Login to claim your rewards!
        </p>
        <div
          className={
            'flex justify-center items-center mt-2 text-indigo-1000 text-[16px] font-semibold'
          }
        >
          <img src={currency} alt={'$'} />{' '}
          <span className={'pr-4'}>+{userInfo?.benefits?.rewards}</span>
          <img src={trophy} alt={'cup'} /> <span>+{userInfo?.benefits?.trophies}</span>
        </div>
      </div>
      <div className="space-y-2 w-full max-w-[315px]">
        <div
          onClick={() => handleOnClickGoogle()}
          className="flex border-2 border-indigo-1000 h-[54px] items-center rounded-full text-indigo-1000 active:border-blue-500 active:text-blue-500 cursor-pointer"
        >
          <div className="flex ml-[16px] mr-[21px] items-center justify-center">
            <img className="w-[25px] h-[25px]" src={gmail} />
          </div>
          <p className="font-semibold text-xl">Continue with Gmail</p>
        </div>
        <FacebookLogin appId="1687310931525698" onSuccess={facebookResponse} className="w-full">
          <div className="flex border-2 border-indigo-1000 h-[54px] items-center rounded-full text-indigo-1000 active:border-blue-500 active:text-blue-500 cursor-pointer">
            <div className="flex ml-[16px] mr-[21px] items-center justify-center">
              <img className="w-[25px] h-[25px]" src={fb} />
            </div>
            <p className="font-semibold text-xl truncate">Continue with Facebook</p>
          </div>
        </FacebookLogin>
      </div>

      <div>
        <p className="text-[18px] text-[#757575] font-normal	text-center">
          By continuing, you agree to our{' '}
          <a className="text-indigo-900" href="/user-agreement">
            User Agreement
          </a>{' '}
          and{' '}
          <a className="text-indigo-900" href="/privacy-policy">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
