import React, { useEffect, useState } from 'react';
import { IAnswer, IOpponent, IOpponentRound } from '../../../../../types/api';
import { isFirstToAnswer, selectFirstName } from '../../../../../helpers/helpers';
import CustomImage from '../../../_shared/CustomImage';
import ThinkPopup from '../ThinkPopup';
import { isEmpty } from 'lodash';
import {
  INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_SECONDS,
  TOTAL_ROUNDS,
} from '../../../../_shared/constants';

interface IRound {
  userTotalScore: number;
  currentRound: number;
  opponentAnswer: IAnswer | null;
  opponent: IOpponent;
  opponentRound: IOpponentRound;
  userAnsweredIn: number | null;
}

const Round = (props: IRound) => {
  const { currentRound, opponent, opponentRound, userTotalScore, userAnsweredIn, opponentAnswer } =
    props;
  const [delayResponse, setDelayResponse] = useState({
    openThinkBox: false,
    oppoScore: opponentRound?.opponentPreviousScore,
    isOppoScore: false,
  });

  const { openThinkBox, oppoScore, isOppoScore } = delayResponse;

  //handle delay to open think popup
  useEffect(() => {
    const delayTimeout = setTimeout(
      () =>
        setDelayResponse((prev) => ({
          ...prev,
          openThinkBox: isFirstToAnswer(userAnsweredIn, opponentRound?.answered_in),
        })),
      1400
    );
    return () => clearTimeout(delayTimeout);
  }, [userAnsweredIn]);

  //handle delay to display opponent score
  useEffect(() => {
    const delayTimeout = setTimeout(
      () =>
        setDelayResponse((prev) => ({
          ...prev,
          oppoScore: opponentRound.total_score,
          isOppoScore: true,
        })),
      (opponentRound?.answered_in + INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_SECONDS + 0.5) * 1000
    );
    return () => clearTimeout(delayTimeout);
  }, [opponentRound]);

  return (
    <div className="text-center col-span-1">
      {!isEmpty(opponent) ? (
        <div className="w-full">
          <div className={'flex justify-end relative'}>
            <div className={'pr-2 truncate'}>
              <p className={'font-bold text-xs text-slate-500 truncate'}>
                {selectFirstName(opponent.display_name)}
              </p>
              <p
                className={`font-bold text-lg ${
                  !isOppoScore
                    ? 'text-white'
                    : isOppoScore && opponentAnswer?.correct
                    ? 'text-green-400'
                    : 'text-red-400'
                }`}
              >
                {oppoScore}
              </p>
            </div>
            <CustomImage
              imageUrl={opponent?.profile_image_url}
              size={42}
              borderColor={'bg-gray-200'}
            />
            <div className={'absolute bottom-[-6px] right-[22px]'}>
              <ThinkPopup isOpen={openThinkBox && !opponentAnswer} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="font-bold text-slate-500 text-xs">Round</p>
          <p className="font-bold text-slate-500 text-lg">
            {currentRound}/{TOTAL_ROUNDS}
          </p>
        </>
      )}
    </div>
  );
};

export default Round;
