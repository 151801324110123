import { useQuery } from 'react-query';
import api from '../../../config/api';
import { IWagerChallenge } from '../../../types/api';

interface IUseChallengeReturn {
  challenge: IWagerChallenge;
}

const useChallenge = ({ challengeId }: { challengeId?: number }): IUseChallengeReturn => {
  const {data: challenge} = useQuery(['challenges'], async () => {
    const response = await api.fetchChallenge({ params: { id: challengeId } });
    return response.data;
  });

  return {
    challenge,
  };
};

export default useChallenge;