import React, { Dispatch, SetStateAction, useEffect } from 'react';
import chevron from '../../../../../assets/svgs/chevron-left.svg';
import failedDeposit from '../../../../../assets/gifs/john-travolta.gif';
import DepositContent from './lib/DepositContent';
import { Preloader, BallTriangle } from 'react-preloader-icon';
import { logEvent } from '../../../../analytics/amplitude';
import { DEPOSIT_PROCESSING_FAILED_SHOWN, DEPOSIT_PROCESSING_SHOWN, DEPOSIT_SCREEN_GO_BACK, DEPOSIT_SCREEN_SHOWN } from '../../../../analytics/types';
import { IUser } from '../../../../../types/api';

interface IDeposit {
  user: IUser;
  loginProviders: {
    facebookResponse: (response) => void;
    handleOnClickGoogle: () => void;
    handleOnClickTwitter: () => void;
    handleOnClickTikTok: () => void;
  };
  depositState: {
    show: boolean;
    processing: boolean;
    failed: boolean;
  };
  setDepositState: Dispatch<
    SetStateAction<{
      show: boolean;
      processing: boolean;
      failed: boolean;
    }>
  >;
}
const IDeposit = (props: IDeposit) => {
  const { depositState, setDepositState, user } = props;
  const { show, processing, failed } = depositState;

  let funnel_step = "tutorial_0";

  if (["/events/basketball"].indexOf(document.location.pathname) >= 0) {
    funnel_step = `regular`
  }

  useEffect(() => {
    logEvent(DEPOSIT_SCREEN_SHOWN)
  }, [])

  useEffect(() => {
    if (failed) {
      logEvent(DEPOSIT_PROCESSING_FAILED_SHOWN, {
        funnel_step,
        user_id: user?.id
      })
    }
  }, [failed])

  useEffect(() => {
    if (processing) {
      logEvent(DEPOSIT_PROCESSING_SHOWN, {
        funnel_step,
        user_id: user?.id
      })
    }
  }, [processing])

  return (
    <div className="flex flex-col pt-8 pb-2 h-full px-6  justify-between items-center">
      <div className={'flex w-full justify-between mb-4'}>
        <img
          src={chevron}
          alt={'cv'}
          className={'hover:cursor-pointer'}
          onClick={() => {
            logEvent(DEPOSIT_SCREEN_GO_BACK)
            setDepositState({ show: false, processing: false, failed: false })
          }}
        />
        <div className={`${failed ? 'text-red-400' : 'text-primary'} text-3xl font-bold`}>
          {failed ? 'Deposit Failed' : 'Deposit'}
        </div>
        <div />
      </div>
      {show && <DepositContent funnel_step={funnel_step} user={user} setDepositState={setDepositState} />}
      {processing && (
        <div className={'h-[60%] flex flex-col items-center'}>
          <Preloader
            use={BallTriangle}
            size={60}
            strokeWidth={6}
            strokeColor="#182C82"
            duration={2000}
            className={'inline-flex justify-center'}
          />
          <div className={'text-gray-400 mt-4'}>Processing...</div>
        </div>
      )}
      {failed && (
        <div className={'h-96 text-center justify-center my-auto items-center flex flex-col'}>
          <img src={failedDeposit} alt={''} className={'inline-flex rounded-full border-8 border-red-400 font-semibold w-48 h-48'} />
          <div className={'text-red-400 mt-4 font-bold mb-20'}>Cannot reach payment provider</div>
        </div>
      )}
    </div>
  );
};

export default IDeposit;
