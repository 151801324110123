import { Action } from '../../types';
import { ITrivia } from '../../../../types/api';
import { GET_TRIVIA, RESET_TRIVIA, SET_ROUND } from '../../actions/trivia';

export interface ITriviaState {
  trivia: ITrivia;
  round: number;
  user_score: number;
}

const defaultState: ITriviaState = {
  trivia: null,
  round: 1,
  user_score: 0,
};

const triviaReducer = (state = defaultState, { type, payload }: Action) => {
  switch (type) {
    case GET_TRIVIA: {
      return {
        ...state,
        trivia: payload,
      };
    }
    case SET_ROUND: {
      return {
        ...state,
        round: state.round + 1,
      };
    }
    case RESET_TRIVIA: {
      return {
        ...state,
        round: 1,
        user_score: 0,
      };
    }
    default:
      return state;
  }
};

export default triviaReducer;
