import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback } from 'react';
import chevronLeft from '../../../../../assets/svgs/chevron-left.svg';
import share from '../../../../../assets/svgs/share.svg';
import { RWebShare } from 'react-web-share';
import discordLogo from '../../../../../assets/svgs/logos/discord.svg';
import twitterLogo from '../../../../../assets/svgs/logos/twitter.svg';

export const BackButton = ({ backLink }: { backLink?: string }) => {
  const navigate = useNavigate();

  const handleOnClickBack = useCallback(() => {
    if (backLink) {
      navigate(backLink);
    } else {
      navigate(-1);
    }
  }, [navigate, backLink]);

  return (
    <div className="cursor-pointer" onClick={handleOnClickBack}>
      <div className="w-12">
        <img className="w-6 h-6 mr-auto" src={chevronLeft} />
      </div>
    </div>
  );
};

export const ShareButton = ({
  title,
  description,
  onShare,
}: {
  onShare?: () => void;
  title?: string;
  description?: string;
}) => {
  const url = window.location.href;

  if (onShare) {
    return (
      <div className="w-12" onClick={onShare}>
        <img className="w-7 h-7 cursor-pointer ml-auto" src={share} />
      </div>
    );
  }

  return (
    <div className="w-12">
      <RWebShare
        data={{
          text: description,
          url,
          title,
        }}
      >
        <img className="w-7 h-7 cursor-pointer ml-auto" src={share} />
      </RWebShare>
    </div>
  );
};

export const DiscordButton = () => {
  return (
    <Link to={'https://discord.gg/eTDfhhwsFb'} target="_blank">
      <img className="w-7 h-7 cursor-pointer" src={discordLogo} />
    </Link>
  );
};

export const TwitterButton = () => {
  return (
    <Link to={'https://twitter.com/Ogmi_io'} target="_blank">
      <img className="w-7 h-7 cursor-pointer" src={twitterLogo} />
    </Link>
  );
};
