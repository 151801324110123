// First we need to import axios.js
import axios from 'axios';
import { ls_token } from '../wager/_shared/constants';
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_BACKEND_API_URL
});

instance.interceptors.request.use(
  (config) => {
    const token = ls_token.get();

    config.headers.common['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Where you would set stuff like your 'Authorization' header, etc ...

// Also add/ configure interceptors && all the other cool stuff
// instance.interceptors.request...

export default instance;