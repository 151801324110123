import React, { useEffect } from 'react';
import { Outlet, createBrowserRouter, useNavigate, useRouteError } from 'react-router-dom';
import { isNull } from 'lodash';

import { identify } from './analytics/amplitude';
import {
  LeaderBoard,
  NotFound,
  PrivacyPolicy,
  SplashScreen,
  PvpBetEvent,
  PvpMatching,
  CountdownScreen,
  Play,
  Rewards,
} from './pages';

import { LS_FIRST_TIME_USER } from './_shared/constants';
import { ID_SCREEN_HEIGHT, ID_SCREEN_WIDTH } from './analytics/types';

function Redirect() {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (error || document.location.pathname === '/events') {
      navigate('/events/basketball');
    }
  }, [error]);

  useEffect(() => {
    if (localStorage) { // check if localStorage is not null
      const firstTimeUser = localStorage.getItem(LS_FIRST_TIME_USER)

      if (isNull(firstTimeUser)) {
        const urlParams = new URLSearchParams(window.location.search);

        // Add UTM parameters to eventData
        for (const param of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'referral_url', 'utm_referral', 'utm_referral_url']) {
          if (urlParams.has(param)) {
            identify(param, urlParams.get(param))
          }
        }

        identify(ID_SCREEN_WIDTH, window.innerWidth)
        identify(ID_SCREEN_HEIGHT, window.innerHeight)

        localStorage.setItem(LS_FIRST_TIME_USER, 'false')
      }
    }
  }, [])

  return <Outlet />;
}

export const wager_router = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    element: <Redirect />,
    children: [
      {
        path: '',
        element: <Outlet />,
        errorElement: <NotFound />,
        children: [
          {
            path: '/privacy-policy',
            element: <PrivacyPolicy />,
          },
          {
            path: '/',
            element: <SplashScreen />,
          },
          {
            path: '/events',
            element: <PvpBetEvent />,
          },
          {
            path: 'events/:slug/leaders-board',
            element: <LeaderBoard />,
          },
          {
            path: 'events/:slug/:challenge_id/matching',
            element: <PvpMatching />,
          },
          {
            path: 'events/:slug',
            element: <PvpBetEvent />,
          },
          {
            path: 'events/:slug/challenges/:challengeId/setup',
            element: <CountdownScreen />,
          },
          {
            path: 'events/:slug/challenges/:challengeId/play',
            element: <Play />,
          },
          {
            path: 'events/:slug/challenges/:challengeId/reward',
            element: <Rewards />,
          },
        ],
      },
    ],
  },
]);
