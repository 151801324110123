import React, { Dispatch, SetStateAction } from 'react';
import PlayersCard from '../PlayersCard';
import TrophyIcon from '../../../../../assets/svgs/cup-icon.svg';
import CryIcon from '../../../../../assets/svgs/cry.svg';
import { IOpponent, IUser } from '../../../../../types/api';
import { selectFirstName } from '../../../../../helpers/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import RewardText from '../RewardText';
import { isEmpty } from 'lodash';
import { logEvent } from '../../../../analytics/amplitude';
import { CLAIM_REWARD_CLICKED, LOGIN_OPTIONS_SHOWN } from '../../../../analytics/types';

interface IMatchResultWithOpponent {
  user: IUser;
  opponent: IOpponent;
  userIsWinner: boolean;
  isDraw: boolean;
  points: number;
  setIsOpenLogin: Dispatch<SetStateAction<boolean>>;
}
const MatchResultWithOpponent = (props: IMatchResultWithOpponent) => {
  const { user, userIsWinner, opponent, isDraw, points, setIsOpenLogin } = props;
  const navigate = useNavigate();
  const { slug, challengeId } = useParams();

  let funnel_step = 'regular';

  if (['0', '1'].indexOf(challengeId) >= 0) {
    funnel_step = `tutorial_${challengeId}`;
  }

  const handleClaimReward = () => {
    logEvent(CLAIM_REWARD_CLICKED, {
      funnel_step,
      user_id: user?.id,
      game_mode: 'fwpvp',
    });
    if (isEmpty(user)) {
      setIsOpenLogin(true);
      logEvent(LOGIN_OPTIONS_SHOWN, {
        game_mode: 'fwpvp',
      });
    } else {
      navigate({ pathname: `/events/${slug}`, search: '?from=result' });
    }
  };

  return (
    <div className={`w-full h-full relative text-center overflow-y-scroll overflow-x-hidden z-50`}>
      {userIsWinner ? (
        <div
          className={'w-full h-full absolute sm:-top-[5%] -top-[6%] sm:left-[4%] left-[0.5%] mx-0'}
        >
          <img src={TrophyIcon} alt={'logo'} />
        </div>
      ) : (
        <img
          src={CryIcon}
          alt={'logo'}
          className={'absolute top-[4%] sm:top-[8%]'}
          style={{ left: 'calc(50% - 52px)' }}
        />
      )}
      <div className="flex justify-center relative sm:mt-52 mt-40">
        <PlayersCard
          player={user}
          isWinner={!isDraw && userIsWinner}
          isUser={true}
          isDraw={isDraw}
        />
        <div className="mx-1.5" />
        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-30 z-10 text-white font-bold text-lg bg-slate-700 p-2 rounded-full justify-center self-center">
          VS
        </span>
        <PlayersCard player={opponent} isWinner={!isDraw && !userIsWinner} isDraw={isDraw} />
      </div>
      <RewardText
        opponentName={selectFirstName(opponent?.display_name)}
        isDraw={isDraw}
        userIsWinner={userIsWinner}
        points={points}
      />
      <div className="w-full flex justify-center items-center mb-2 absolute sm:bottom-6 bottom-2">
        <div className={'w-64 px-6'}>
          <div
            onClick={
              isDraw || !userIsWinner ? () => navigate(`/events/${slug}`) : handleClaimReward
            }
            className={`flex flex-1 text-xl text-white justify-center items-center bg-pink-500 h-14 text-white font-bold rounded-xl cursor-pointer active:bg-pink-600 transition duration-300 ease-in-out hover:opacity-90`}
          >
            {isDraw || !userIsWinner ? 'Next' : 'Claim reward'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchResultWithOpponent;
