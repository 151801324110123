import { Action } from '../../types';
import { GET_VARIANT_A, GET_VARIANT_B } from '../../actions/variants';

export interface IVariantState {
  variant: { spotlight: boolean; singleChallenge: boolean };
}

const defaultState: IVariantState = {
  variant: {
    spotlight: true,
    singleChallenge: false,
  },
};

const variantsReducer = (state = defaultState, { type, payload }: Action) => {
  switch (type) {
    case GET_VARIANT_A: {
      return {
        ...state,
        variant: {
          spotlight: false,
          singleChallenge: false,
        },
      };
    }
    case GET_VARIANT_B: {
      return {
        ...state,
        variant: {
          spotlight: false,
          singleChallenge: true,
        },
      };
    }
    default:
      return state;
  }
};

export default variantsReducer;
