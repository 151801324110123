export const saveTokenLocally = (token: string) => {
  localStorage.setItem('token', token);
};

export const getTokenLocally = () => {
  return localStorage.getItem('token');
};

export const deleteTokenLocally = () => {
  localStorage.removeItem('token');
};

export function percentageFormatter(current?: number, total?: number) {
  const safeCheck =
    total === 0 ||
    total === undefined ||
    total === null ||
    current === 0 ||
    current === undefined ||
    current === null;

  if (safeCheck) {
    return 0;
  }

  return parseFloat(`${(current / total) * 100}`).toFixed(0);
}

export function formatTimeLeft(secondsLeft: number) {
  if (secondsLeft <= 0) {
    return 'Event has ended';
  }

  const minutesLeft = Math.floor(secondsLeft / 60);
  const hoursLeft = Math.floor(minutesLeft / 60);

  if (hoursLeft > 0) {
    return `${hoursLeft}hrs left`;
  }

  return `${minutesLeft}mins left`;
}

export const isCorrectCheck = (correctAnswer, currentAnswer) => {
  return correctAnswer !== null && currentAnswer === correctAnswer;
};

export const isWrongCheck = (correctAnswer, currentAnswer) => {
  return correctAnswer !== null && currentAnswer !== correctAnswer;
};

export const didTheUserClickedOnThisAnswer = (selectedAnswer, currentAnswer) => {
  return selectedAnswer !== null && selectedAnswer === currentAnswer;
};

export const selectFirstName = (fullName: string) => {
  return fullName?.split(' ')?.[0];
};

export const isFirstToAnswer = (
  userAnsweredIn: number | null,
  opponentAnsweredIn: number | null
) => {
  return userAnsweredIn !== null && userAnsweredIn < opponentAnsweredIn;
};
