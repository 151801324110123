import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import rankingLogsHandler from '../../../services/rankingLogs';
import eventHandler from '../../../services/events/show';
import Page from '../../components/_shared/Page';
import LoadingSpinner from '../../components/_shared/LoadingSpinner';
import LeaderboardScreen from '../../components/LeaderboardScreen';
import usePageMeta from '../../../hooks/usePageMeta';
import { logEvent } from '../../analytics/amplitude';
import { LEADERBOARD_SHOWN } from '../../analytics/types';
import { useUser } from '../../hooks/user';

function LeaderboardWrapper() {
  const { slug } = useParams();
  const response = rankingLogsHandler({ params: { event_slug: slug } });
  const eventResponse = eventHandler({ params: { slug } });
  const { user } = useUser();

  usePageMeta({});

  useEffect(() => {
    logEvent(LEADERBOARD_SHOWN, {
      event_category: "sports",
      event_topic: "basketball",
      user_id: user?.id
    })
  }, [])

  if (response.status === 'success')
    return (
      <Page className="bg-primary" hasOpacityTransition>
        <LeaderboardScreen players={response.data} event={eventResponse.data} />
      </Page>
    );

  if (response.status === 'loading')
    return (
      <Page className="bg-primary">
        <LoadingSpinner show />
      </Page>
    );

  return null;
}

export default LeaderboardWrapper;
