import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import avatar from '../../../../../assets/images/blank_profile_picture.png';
import type { IUser as IUserType } from '../../../../../types/api';
import { IAppData } from '../../../../app-context';
import {
  DEPOSIT_BUTTON_CLICKED,
  PLAYER_PROFILE_SHOWN,
  WITHDRAW_BUTTON_CLICKED,
} from '../../../../analytics/types';
import { logEvent } from '../../../../analytics/amplitude';

interface IUser {
  user: IUserType;
  userInfo: Partial<IAppData>;
  setDepositState: Dispatch<
    SetStateAction<{
      show: boolean;
      processing: boolean;
      failed: boolean;
    }>
  >;
}
const User = (props: IUser) => {
  const { setDepositState, user, userInfo } = props;
  const { benefits, game_played } = userInfo ?? {};
  const successRate =
    benefits?.trophies % 3 === 0 ? '100%' : (1 - benefits.trophies / game_played) * 100;

  let funnel_step = 'tutorial_0';

  if (['/events/basketball'].indexOf(document.location.pathname) >= 0) {
    funnel_step = `regular`;
  }

  useEffect(() => {
    logEvent(PLAYER_PROFILE_SHOWN, {
      funnel_step: funnel_step,
      user_id: user?.id,
    });
  }, []);

  return (
    <div className="flex flex-col px-8 pt-10 pb-3  items-center ">
      <div className={'text-primary text-3xl font-bold mb-5'}>{user?.display_name ?? 'You'}</div>
      <div className={'rounded-full  border-slate-100 border-[6px] mb-6'}>
        <img src={user?.profile_image_url ?? avatar} className={'w-28 h-28 rounded-full'} />
      </div>
      <div className={'flex justify-between w-full px-10 mb-6'}>
        <div className={'text-center'}>
          <div className={'font-bold text-xl'}>{game_played || 0}</div>
          <div className={'text-gray-400'}>Played</div>
        </div>
        <div className={'h-[28px] border mt-4'} />
        <div className={'text-center'}>
          <div className={'font-bold text-xl'}>{successRate || 0}</div>
          <div className={'text-gray-400'}>Wins</div>
        </div>
        <div className={'h-[28px] border mt-4'} />
        <div className={'text-center'}>
          <div className={'font-bold text-xl'}>{benefits?.trophies || 0}</div>
          <div className={'text-gray-400'}>Trophies</div>
        </div>
      </div>
      <div
        className={
          'w-full px-5 pt-6 pb-3 bg-cyan-50 rounded-2xl flex flex-col justify-center items-center text-primary '
        }
      >
        <div className={'font-bold text-xl mb-2'}>Total Balance</div>
        <div className={'text-2xl mb-2'}>${(benefits?.rewards ?? 0).toFixed(2)}</div>
        <div className={'text-gray-400 mb-2 text-sm text-center'}>
          Reach $10 balance to withdraw your winnings
        </div>
        <div className={'font-bold flex justify-between sm:w-[85%]'}>
          <motion.div
            initial={{ scale: 1 }}
            whileTap={{ scale: 0.9 }}
            className={'text-center border-[2px] border-primary rounded-xl px-6 py-2 min-w-[130px]'}
            onClick={() => {
              logEvent(DEPOSIT_BUTTON_CLICKED, {
                funnel_step,
                user_id: user?.id,
                player_money_balance: benefits?.rewards ?? 0,
              });
              setDepositState({ show: true, processing: false, failed: false });
            }}
          >
            Deposit
          </motion.div>
          <div
            className={
              'text-center text-gray-400 border-[2px] border-gray-400 rounded-xl px-6 py-2 min-w-[130px]'
            }
            onClick={() => {
              logEvent(WITHDRAW_BUTTON_CLICKED, {
                funnel_step,
                user_id: user?.id,
                player_money_balance: benefits?.rewards ?? 0,
              });
            }}
          >
            Withdraw
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
