import React from 'react';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Page from '../../components/_shared/Page';
import usePageMeta from '../../../hooks/usePageMeta';

function PrivacyPolicyWrapper() {
  usePageMeta({});

  return (
    <Page className="bg-primary">
      <PrivacyPolicy />
    </Page>
  );
}

export default PrivacyPolicyWrapper;
