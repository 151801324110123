import React from 'react';

const RewardText = ({
  opponentName,
  isDraw,
  userIsWinner,
  points,
}: {
  opponentName: string;
  isDraw: boolean;
  userIsWinner: boolean;
  points: number;
}) => (
  <div className={'text-white font-bold text-2xl sm:my-20 my-10'}>
    {isDraw ? (
      <div>
        tie with <span className={'text-pink-500'}>@{opponentName ?? 'opponent'}</span>
      </div>
    ) : !isDraw && userIsWinner ? (
      <>
        You beat <span className={'text-pink-500'}>@{opponentName ?? 'You'}</span> to earn{' '}
        <div className={'text-4xl pt-3'}> ${(points ?? 0).toFixed(2)}</div>
      </>
    ) : (
      <>
        <span className={'text-pink-500'}>@{opponentName ?? 'Opponent'}</span> won to earn{' '}
        <div className={'text-4xl pt-3'}> ${(points ?? 0).toFixed(2)}</div>
      </>
    )}
  </div>
);

export default RewardText;
