// amplitude.ts
import * as amplitude from '@amplitude/analytics-browser';
import { firebaseLogEvent } from '../../analytics/firebase';

export const initAmplitude = (apiKey: string): void => {
  amplitude.init(apiKey);
};

export const logEvent = (eventName: string, eventData?: Record<string, unknown>): void => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    console.log({ eventName, eventData });
  }

  // Get URL parameters
  const urlParams = new URLSearchParams(window.location.search);

  // Add UTM parameters to eventData
  for (const param of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']) {
    if (urlParams.has(param)) {
      eventData[param] = urlParams.get(param);
    }
  }

  // Log event with added UTM parameters
  firebaseLogEvent(eventName, eventData);
  amplitude.logEvent(eventName, eventData);
};

export const identify = (property: string, value: amplitude.Types.ValidPropertyType) => {
  const id = new amplitude.Identify();
  id.set(property, value);
  amplitude.identify(id);
};
