import React, { useEffect, useState } from 'react';
import circlesLeft from '../../../../assets/images/circles_left.png';
import circlesRight from '../../../../assets/images/circles_right.png';
import ogmiLogo from '../../../../assets/svgs/logos/ogmi.svg';

function Page({
  children,
  className,
  hasLogo,
  hasOpacityTransition,
}: {
  children: React.ReactNode;
  className?: string;
  hasLogo?: boolean;
  hasOpacityTransition?: boolean;
}) {
  const [contentVisible, setContentVisible] = useState(false);
  const skipOpacityTransition = !hasOpacityTransition;

  useEffect(() => {
    if (hasOpacityTransition) {
      const timeout = setTimeout(() => {
        setContentVisible(true);
      }, 0);
      return () => clearTimeout(timeout);
    }
  }, [setContentVisible, hasOpacityTransition]);

  return (
    <div
      className={`flex relative self-center justify-center items-center w-full h-full ${className ? className : 'bg-black'
        }`}
    >
      {hasLogo && (
        <div className="lg:flex absolute top-12 left-12 w-20 hidden">
          <img src={ogmiLogo} />
        </div>
      )}

      <div className={`lg:flex self-center justify-start h-full hidden`}>
        <Image src={circlesRight} />
      </div>

      <div
        className={`overflow-y-auto flex self-center justify-center h-full w-full max-w-md mx-auto opacity-0 ${skipOpacityTransition && 'opacity-100'
          } ${contentVisible && 'transition-opacity duration-400 opacity-100'}`}
      >
        {children}
      </div>

      <div className={`lg:flex self-center justify-end h-full hidden`}>
        <Image src={circlesLeft} />
      </div>
    </div>
  );
}

export default Page;

function Image({ src, className }: { src: string; className?: string }) {
  return (
    <img src={src} className={`object-fit h-full w-11/12 ${className ? className : ''}`} alt="" />
  );
}
