import React, { Dispatch, SetStateAction, useRef } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { useLogin } from '../../hooks/login';
import Login from './lib/Login';
import { IAppData } from '../../app-context';

function LoginModal({
  isOpen,
  setIsOpenLogin,
  userInfo,
}: {
  isOpen: boolean;
  setIsOpenLogin: Dispatch<SetStateAction<boolean>>;
  userInfo: Partial<IAppData>;
}) {
  const ref = useRef<SheetRef>();
  const loginProviders = useLogin({ setIsOpenLogin });

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpenLogin(false)}
      snapPoints={[400, 400, 0]}
      initialSnap={1}
      className="max-w-md mx-auto"
    >
      <Sheet.Container style={{ borderTopLeftRadius: '10%', borderTopRightRadius: '10%' }}>
        <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
          <Login loginProviders={loginProviders} userInfo={userInfo} setOpen={setIsOpenLogin} />
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}

export default LoginModal;
