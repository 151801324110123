import { IEvent } from '../../../types/api';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useQuery } from 'react-query';
import api from '../../../config/api';
import { getEvent } from '../../redux/actions/events';

interface IUseEventReturn {
  event: { data: IEvent; status: number };
}

export const useEvent = ({ params }: { params: Record<string, any> }): IUseEventReturn => {
  const dispatch = useAppDispatch();
  const { event } = useAppSelector((state) => state.events);

  useQuery(
    ['event', params?.slug],
    async () => {
      const response = await api.fetchEvent({ params });
      dispatch(getEvent(response));
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    event,
  };
};
