import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import usePageMeta from '../../../../hooks/usePageMeta';
import { ROUND_TRANSITION_1_SHOWN, ROUND_TRANSITION_2_SHOWN, ROUND_TRANSITION_3_SHOWN } from '../../../analytics/types';
import { logEvent } from '../../../analytics/amplitude';
import { useUser } from '../../../hooks/user';

export default function ScreenTransition() {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();
  const { user } = useUser();
  const { challengeId } = useParams();

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      navigate(document.location.pathname.replace('setup', 'play'));
    }
  }, [count, history]);

  let funnel_step = "regular";

  if (["0", "1"].indexOf(challengeId) >= 0) {
    funnel_step = `tutorial_${challengeId}`
  }

  const logTransition = (event: string) => {
    logEvent(event, {
      funnel_step,
      game_mode: 'fwpvp',
      challenge_number: challengeId,
      user_id: user?.id
    })
  }

  const renderContent = useMemo(() => {
    switch (count) {
      case 3:
        logTransition(ROUND_TRANSITION_3_SHOWN)
        return {
          title: 'Get ready',
          background: 'bg-indigo-900',
          themeColor: '#182C82',
        };
      case 2:
        logTransition(ROUND_TRANSITION_2_SHOWN)
        return {
          title: 'Think quick',
          background: 'bg-indigo-1000',
          themeColor: '#0F1E61',
        };
      default:
        logTransition(ROUND_TRANSITION_1_SHOWN)
        return {
          title: 'Let’s goooo...',
          background: 'bg-indigo-1100',
          themeColor: '#0C1746',
        };
    }
  }, [count]);

  usePageMeta({ themeColor: renderContent.themeColor });

  return (
    <div className={`flex flex-1 flex-col ${renderContent.background}`}>
      <div className="flex flex-1 flex-col items-center justify-center mb-12">
        <div className="flex flex-col px-4 justify-center">
          <h2 className="text-80 font-bold text-center text-white py-4">{count}</h2>
          <h2 className="text-50 font-bold text-center text-white py-4">{renderContent.title}</h2>
        </div>
      </div>
    </div>
  );
}
