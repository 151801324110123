import { Action } from '../../types';
import { IEvent } from '../../../../types/api';
import { GET_TOP_PLAYERS } from '../../actions/top-players';

export interface ITopPlayersState {
  topPlayers: { data: IEvent; status: string };
}

const defaultState: ITopPlayersState = {
  topPlayers: {
    data: null,
    status: null,
  },
};

const topPlayersReducer = (state = defaultState, { type, payload }: Action) => {
  switch (type) {
    case GET_TOP_PLAYERS: {
      return {
        ...state,
        topPlayers: {
          data: payload.data,
          status: payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export default topPlayersReducer;
