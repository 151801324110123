import { Link } from 'react-router-dom';
import ogmiLogo from '../../../../assets/svgs/logos/ogmi.svg';
import React from 'react';

export const OgmiLogoButton = () => {
  return (
    <Link to={'/events'}>
      <img className="w-12 h-12 mr-auto cursor-pointer" src={ogmiLogo} />
    </Link>
  );
};
