import { IEvent, IUser } from '../../types/api';

type Answer = {
  title: string;
  correct: boolean;
};

type Round = {
  question: string;
  answers: Answer[];
  total_score?: number;
  opponentPreviousScore?: number;
  opponentAnswerPosition?: number;
};

export type Challenge = {
  challenger?: IUser;
  event: IEvent;
  id: number;
  challenge_entry_fee: number | string;
  challenge_reward: number | string;
  challenge_skill: 'easy' | 'medium' | 'hard';
  rounds: Round[];
  challenger_id: number;
};

export const event: IEvent = {
  id: '1',
  name: 'Basketball Trivia',
  slug: 'event_slug',
  banner_image_url: 'https://iili.io/HP9eBRI.png',
  profile_image_url: 'https://iili.io/HP9eaO7.png',
  seconds_left: 0,
  end_date: '',
  metadata: {
    total_challenges: 0,
    total_challenges_played: 0,
  },
  category: {
    id: 0,
    title: 'Sports',
  },
  subCategory: {
    id: 0,
    title: 'Basketball',
  },
  description:
    "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
};

export default {
  challenges: [
    {
      id: 1,
      challenge_skill: 'easy',
      challenge_entry_fee: 0,
      challenge_reward: 1,
      challenger_id: 1,
      rounds: [
        {
          question: 'Can you use your feet to play basketball?',
          answers: [
            {
              title: 'No',
              correct: true,
            },
            {
              title: 'Yes',
              correct: false,
            },
            {
              title: 'Only on Wednesdays',
              correct: false,
            },
            {
              title: "Only if it's cloudy",
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 7.2,
        },
        {
          question: 'Do basketball players use a bat to play the game?',
          answers: [
            {
              title: 'No',
              correct: true,
            },
            {
              title: 'Yes',
              correct: false,
            },
            {
              title: 'Only on Tuesdays',
              correct: false,
            },
            {
              title: "Only if it's raining",
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 3.3,
        },
        {
          question: 'Do you need to bounce the ball while moving in basketball?',
          answers: [
            {
              title: 'Yes',
              correct: true,
            },
            {
              title: 'No',
              correct: false,
            },
            {
              title: 'Only on Mondays',
              correct: false,
            },
            {
              title: "Only if it's sunny",
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 2,
          opponentTotalScore: 3,
          answered_in: 3.1,
        },
        {
          question: 'Do you throw or kick the basketball into the hoop to score?',
          answers: [
            {
              title: 'Throw',
              correct: true,
            },
            {
              title: 'Kick',
              correct: false,
            },
            {
              title: 'Both',
              correct: false,
            },
            {
              title: 'Neither',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 3,
          opponentTotalScore: 4,
          answered_in: 2.3,
        },
        {
          question: 'Is a basketball bigger or smaller than a soccer ball?',
          answers: [
            {
              title: 'About the same',
              correct: true,
            },
            {
              title: 'Bigger',
              correct: false,
            },
            {
              title: 'Smaller',
              correct: false,
            },
            {
              title: 'None of the above',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 4,
          opponentTotalScore: 5,
          answered_in: 7,
        },
        {
          question: 'Is basketball typically played indoors or outdoors?',
          answers: [
            {
              title: 'Both',
              correct: true,
            },
            {
              title: 'Only indoors',
              correct: false,
            },
            {
              title: 'Only outdoors',
              correct: false,
            },
            {
              title: 'Only in space',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 5,
          opponentTotalScore: 6,
          answered_in: 3.8,
        },
        {
          question: 'Is running allowed in basketball?',
          answers: [
            {
              title: 'Yes',
              correct: true,
            },
            {
              title: 'No',
              correct: false,
            },
            {
              title: 'Only on Saturdays',
              correct: false,
            },
            {
              title: 'Only in the summer',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 6,
          opponentTotalScore: 7,
          answered_in: 7.6,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 7,
    },
    {
      id: 2,
      challenge_skill: 'easy',
      challenge_entry_fee: 0,
      challenge_reward: 1,
      challenger_id: 2,
      rounds: [
        {
          question: 'What color is a traditional basketball?',
          answers: [
            {
              title: 'Orange',
              correct: true,
            },
            {
              title: 'Blue',
              correct: false,
            },
            {
              title: 'Red',
              correct: false,
            },
            {
              title: 'Green',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 4.5,
        },
        {
          question: 'What do players wear on their feet when they play basketball?',
          answers: [
            {
              title: 'Sneakers',
              correct: true,
            },
            {
              title: 'Flip flops',
              correct: false,
            },
            {
              title: 'Slippers',
              correct: false,
            },
            {
              title: 'Boots',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 8.4,
        },
        {
          question:
            'What do we call the ten-foot-high basket where players throw the ball to score?',
          answers: [
            {
              title: 'Hoop',
              correct: true,
            },
            {
              title: 'Goal',
              correct: false,
            },
            {
              title: 'Net',
              correct: false,
            },
            {
              title: 'Target',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 2,
          opponentTotalScore: 3,
          answered_in: 8.3,
        },
        {
          question: 'What is a basketball?',
          answers: [
            {
              title: 'A type of ball',
              correct: true,
            },
            {
              title: 'A type of bike',
              correct: false,
            },
            {
              title: 'A type of book',
              correct: false,
            },
            {
              title: 'A type of food',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 3,
          opponentTotalScore: 4,
          answered_in: 3.4,
        },
        {
          question: 'What is the full form of NBA - National ______ Association?',
          answers: [
            {
              title: 'Basketball',
              correct: true,
            },
            {
              title: 'Baseball',
              correct: false,
            },
            {
              title: 'Bowling',
              correct: false,
            },
            {
              title: 'Badminton',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 4,
          opponentTotalScore: 5,
          answered_in: 8.1,
        },
        {
          question: 'What is the name of the place where you throw the basketball to score points?',
          answers: [
            {
              title: 'Basket',
              correct: true,
            },
            {
              title: 'Pool',
              correct: false,
            },
            {
              title: 'Box',
              correct: false,
            },
            {
              title: 'Net',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 5,
          opponentTotalScore: 6,
          answered_in: 2.2,
        },
        {
          question: 'What is the shape of a basketball?',
          answers: [
            {
              title: 'Round',
              correct: true,
            },
            {
              title: 'Square',
              correct: false,
            },
            {
              title: 'Triangle',
              correct: false,
            },
            {
              title: 'Oval',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 6,
          opponentTotalScore: 7,
          answered_in: 7.4,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 7,
    },
    {
      id: 3,
      challenge_skill: 'medium',
      challenge_entry_fee: 1,
      challenge_reward: 2,
      challenger_id: 3,
      rounds: [
        {
          question: 'Who was the first player to win 8 NBA scoring titles?',
          answers: [
            {
              title: 'Michael Jordan',
              correct: true,
            },
            {
              title: 'Kareem Abdul-Jabbar',
              correct: false,
            },
            {
              title: 'Wilt Chamberlain',
              correct: false,
            },
            {
              title: 'Kobe Bryant',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 2.7,
        },
        {
          question: 'Which team did Michael Jordan win all of his 6 Championships with?',
          answers: [
            {
              title: 'Chicago Bulls',
              correct: true,
            },
            {
              title: 'Washington Wizards',
              correct: false,
            },
            {
              title: 'Charlotte Hornets',
              correct: false,
            },
            {
              title: 'Miami Heat',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 2.4,
        },
        {
          question: 'Which NBA team selected Michael Jordan in the 1984 NBA Draft?',
          answers: [
            {
              title: 'Chicago Bulls',
              correct: true,
            },
            {
              title: 'Portland Trail Blazers',
              correct: false,
            },
            {
              title: 'Houston Rockets',
              correct: false,
            },
            {
              title: 'Los Angeles Lakers',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 2,
          opponentTotalScore: 3,
          answered_in: 7,
        },
        {
          question: 'What is the time limit for possession in the NBA?',
          answers: [
            {
              title: '24 seconds',
              correct: true,
            },
            {
              title: '30 seconds',
              correct: false,
            },
            {
              title: '20 seconds',
              correct: false,
            },
            {
              title: '35 seconds',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 3,
          opponentTotalScore: 58,
          answered_in: 4.5,
        },
        {
          question: 'How many seconds does an NBA team have to advance the ball past half-court?',
          answers: [
            {
              title: '8 seconds',
              correct: true,
            },
            {
              title: '10 seconds',
              correct: false,
            },
            {
              title: '12 seconds',
              correct: false,
            },
            {
              title: '15 seconds',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 58,
          opponentTotalScore: 59,
          answered_in: 4.1,
        },
        {
          question:
            'In the NBA what is the maximum number of players a team can have on their active roster?',
          answers: [
            {
              title: '15',
              correct: true,
            },
            {
              title: '12',
              correct: false,
            },
            {
              title: '18',
              correct: false,
            },
            {
              title: '20',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 59,
          opponentTotalScore: 60,
          answered_in: 2.1,
        },
        {
          question: 'In the NBA how long is halftime?',
          answers: [
            {
              title: '15 minutes',
              correct: true,
            },
            {
              title: '10 minutes',
              correct: false,
            },
            {
              title: '20 minutes',
              correct: false,
            },
            {
              title: '30 minutes',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 60,
          opponentTotalScore: 61,
          answered_in: 6.8,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 61,
    },
    {
      id: 4,
      challenge_skill: 'medium',
      challenge_entry_fee: 1,
      challenge_reward: 2,
      challenger_id: 4,
      rounds: [
        {
          question: 'In the NBA how long does each quarter last?',
          answers: [
            {
              title: '12 minutes',
              correct: true,
            },
            {
              title: '10 minutes',
              correct: false,
            },
            {
              title: '15 minutes',
              correct: false,
            },
            {
              title: '20 minutes',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 7.1,
        },
        {
          question: 'In the NBA how far is the free throw line from the baseline?',
          answers: [
            {
              title: '15 feet',
              correct: true,
            },
            {
              title: '14 feet',
              correct: false,
            },
            {
              title: '16 feet',
              correct: false,
            },
            {
              title: '17 feet',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 1,
          opponentTotalScore: 56,
          answered_in: 4.5,
        },
        {
          question: 'In NBA how many points is a free throw worth?',
          answers: [
            {
              title: '1',
              correct: true,
            },
            {
              title: '2',
              correct: false,
            },
            {
              title: '3',
              correct: false,
            },
            {
              title: '0',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 56,
          opponentTotalScore: 57,
          answered_in: 8.2,
        },
        {
          question: 'How wide is the key in the NBA?',
          answers: [
            {
              title: '16 feet',
              correct: true,
            },
            {
              title: '12 feet',
              correct: false,
            },
            {
              title: '14 feet',
              correct: false,
            },
            {
              title: '18 feet',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 57,
          opponentTotalScore: 58,
          answered_in: 3.8,
        },
        {
          question: 'How many timeouts are teams allowed per game in the NBA?',
          answers: [
            {
              title: '7',
              correct: true,
            },
            {
              title: '5',
              correct: false,
            },
            {
              title: '6',
              correct: false,
            },
            {
              title: '8',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 58,
          opponentTotalScore: 59,
          answered_in: 4.3,
        },
        {
          question: 'How many seconds does an NBA team have to advance the ball past half-court?',
          answers: [
            {
              title: '8 seconds',
              correct: true,
            },
            {
              title: '10 seconds',
              correct: false,
            },
            {
              title: '12 seconds',
              correct: false,
            },
            {
              title: '15 seconds',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 59,
          opponentTotalScore: 75,
          answered_in: 8.4,
        },
        {
          question: 'How many referees officiate a typical NBA game?',
          answers: [
            {
              title: '3',
              correct: true,
            },
            {
              title: '2',
              correct: false,
            },
            {
              title: '4',
              correct: false,
            },
            {
              title: '5',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 75,
          opponentTotalScore: 76,
          answered_in: 5.5,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 76,
    },
    {
      id: 5,
      challenge_skill: 'medium',
      challenge_entry_fee: 1,
      challenge_reward: 2,
      challenger_id: 5,
      rounds: [
        {
          question: 'How many players are allowed on the court for each team during an NBA game?',
          answers: [
            {
              title: '5',
              correct: true,
            },
            {
              title: '6',
              correct: false,
            },
            {
              title: '7',
              correct: false,
            },
            {
              title: '4',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 3.8,
        },
        {
          question: "How many feet from the basket is the NBA's three-point line in the corners?",
          answers: [
            {
              title: '22 feet',
              correct: true,
            },
            {
              title: '23 feet 9 inches',
              correct: false,
            },
            {
              title: '20 feet',
              correct: false,
            },
            {
              title: '24 feet',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 7.4,
        },
        {
          question: 'How long is the shot clock in an NBA overtime period?',
          answers: [
            {
              title: '24 seconds',
              correct: true,
            },
            {
              title: '14 seconds',
              correct: false,
            },
            {
              title: '30 seconds',
              correct: false,
            },
            {
              title: '20 seconds',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 2,
          opponentTotalScore: 3,
          answered_in: 8,
        },
        {
          question: "How long is the NBA's regular season?",
          answers: [
            {
              title: '82 games',
              correct: true,
            },
            {
              title: '76 games',
              correct: false,
            },
            {
              title: '70 games',
              correct: false,
            },
            {
              title: '90 games',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 3,
          opponentTotalScore: 4,
          answered_in: 2.1,
        },
        {
          question: 'According to NBA rules how high is the basketball hoop from the ground?',
          answers: [
            {
              title: '10 feet',
              correct: true,
            },
            {
              title: '12 feet',
              correct: false,
            },
            {
              title: '9 feet',
              correct: false,
            },
            {
              title: '11 feet',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 4,
          opponentTotalScore: 5,
          answered_in: 4,
        },
        {
          question: 'Who won the NBA MVP Award for the 2018-2019 season?',
          answers: [
            {
              title: 'Giannis Antetokounmpo',
              correct: true,
            },
            {
              title: 'LeBron James',
              correct: false,
            },
            {
              title: 'Stephen Curry',
              correct: false,
            },
            {
              title: 'James Harden',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 5,
          opponentTotalScore: 59,
          answered_in: 4.6,
        },
        {
          question:
            'Who was the first player to be drafted number 1 overall straight out of high school?',
          answers: [
            {
              title: 'Kwame Brown',
              correct: true,
            },
            {
              title: 'LeBron James',
              correct: false,
            },
            {
              title: 'Dwight Howard',
              correct: false,
            },
            {
              title: 'Kevin Garnett',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 59,
          opponentTotalScore: 60,
          answered_in: 3.8,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 60,
    },
    {
      id: 6,
      challenge_skill: 'medium',
      challenge_entry_fee: 1,
      challenge_reward: 2,
      challenger_id: 6,
      rounds: [
        {
          question: 'Which team did Michael Jordan win all of his 6 Championships with?',
          answers: [
            {
              title: 'Chicago Bulls',
              correct: true,
            },
            {
              title: 'Washington Wizards',
              correct: false,
            },
            {
              title: 'Charlotte Hornets',
              correct: false,
            },
            {
              title: 'Miami Heat',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 6.7,
        },
        {
          question: "Which player was known as 'The Answer'?",
          answers: [
            {
              title: 'Allen Iverson',
              correct: true,
            },
            {
              title: 'Kobe Bryant',
              correct: false,
            },
            {
              title: "Shaquille O'Neal",
              correct: false,
            },
            {
              title: 'Tim Duncan',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 8.1,
        },
        {
          question:
            'Who was the first player in NBA history to be elected league MVP by unanimous vote?',
          answers: [
            {
              title: 'Stephen Curry',
              correct: true,
            },
            {
              title: 'LeBron James',
              correct: false,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Kareem Abdul-Jabbar',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 2,
          opponentTotalScore: 3,
          answered_in: 4.7,
        },
        {
          question: "Who is known as 'The Logo' of the NBA?",
          answers: [
            {
              title: 'Jerry West',
              correct: true,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
            {
              title: 'Bill Russell',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 3,
          opponentTotalScore: 4,
          answered_in: 3.1,
        },
        {
          question: "Which NBA legend is known as 'The Dream'?",
          answers: [
            {
              title: 'Hakeem Olajuwon',
              correct: true,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
            {
              title: 'Larry Bird',
              correct: false,
            },
            {
              title: "Shaquille O'Neal",
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 4,
          opponentTotalScore: 5,
          answered_in: 7.1,
        },
        {
          question: 'Who was the first NBA player to score 100 points in a single game?',
          answers: [
            {
              title: 'Wilt Chamberlain',
              correct: true,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Kareem Abdul-Jabbar',
              correct: false,
            },
            {
              title: 'Kobe Bryant',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 5,
          opponentTotalScore: 25,
          answered_in: 8,
        },
        {
          question: "Which NBA legend is known for his 'Sky Hook' shot?",
          answers: [
            {
              title: 'Kareem Abdul-Jabbar',
              correct: true,
            },
            {
              title: 'Bill Russell',
              correct: false,
            },
            {
              title: 'Wilt Chamberlain',
              correct: false,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 25,
          opponentTotalScore: 26,
          answered_in: 3,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 26,
    },
    {
      id: 7,
      challenge_skill: 'medium',
      challenge_entry_fee: 1,
      challenge_reward: 2,
      challenger_id: 7,
      rounds: [
        {
          question: 'Which team did Michael Jordan win all of his 6 Championships with?',
          answers: [
            {
              title: 'Chicago Bulls',
              correct: true,
            },
            {
              title: 'Washington Wizards',
              correct: false,
            },
            {
              title: 'Charlotte Hornets',
              correct: false,
            },
            {
              title: 'Miami Heat',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 6.3,
        },
        {
          question: "Which player was known as 'The Answer'?",
          answers: [
            {
              title: 'Allen Iverson',
              correct: true,
            },
            {
              title: 'Kobe Bryant',
              correct: false,
            },
            {
              title: "Shaquille O'Neal",
              correct: false,
            },
            {
              title: 'Tim Duncan',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 5.3,
        },
        {
          question:
            'Who was the first player in NBA history to be elected league MVP by unanimous vote?',
          answers: [
            {
              title: 'Stephen Curry',
              correct: true,
            },
            {
              title: 'LeBron James',
              correct: false,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Kareem Abdul-Jabbar',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 2,
          opponentTotalScore: 19,
          answered_in: 8.3,
        },
        {
          question: "Who is known as 'The Logo' of the NBA?",
          answers: [
            {
              title: 'Jerry West',
              correct: true,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
            {
              title: 'Bill Russell',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 19,
          opponentTotalScore: 20,
          answered_in: 6.9,
        },
        {
          question: "Which NBA legend is known as 'The Dream'?",
          answers: [
            {
              title: 'Hakeem Olajuwon',
              correct: true,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
            {
              title: 'Larry Bird',
              correct: false,
            },
            {
              title: "Shaquille O'Neal",
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 20,
          opponentTotalScore: 44,
          answered_in: 7.6,
        },
        {
          question: 'Who was the first NBA player to score 100 points in a single game?',
          answers: [
            {
              title: 'Wilt Chamberlain',
              correct: true,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Kareem Abdul-Jabbar',
              correct: false,
            },
            {
              title: 'Kobe Bryant',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 44,
          opponentTotalScore: 45,
          answered_in: 3.1,
        },
        {
          question: "Which NBA legend is known for his 'Sky Hook' shot?",
          answers: [
            {
              title: 'Kareem Abdul-Jabbar',
              correct: true,
            },
            {
              title: 'Bill Russell',
              correct: false,
            },
            {
              title: 'Wilt Chamberlain',
              correct: false,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 45,
          opponentTotalScore: 66,
          answered_in: 7.9,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 66,
    },
    {
      id: 8,
      challenge_skill: 'hard',
      challenge_entry_fee: 2,
      challenge_reward: 4,
      challenger_id: 8,
      rounds: [
        {
          question: "Who was known as 'The Hick from French Lick'?",
          answers: [
            {
              title: 'Larry Bird',
              correct: true,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Bill Russell',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 6.4,
        },
        {
          question: 'Which NBA legend is known for his rivalry with Magic Johnson?',
          answers: [
            {
              title: 'Larry Bird',
              correct: true,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Bill Russell',
              correct: false,
            },
            {
              title: 'Kareem Abdul-Jabbar',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 6.6,
        },
        {
          question: "Which NBA legend was nicknamed 'The Big Fundamental'?",
          answers: [
            {
              title: 'Tim Duncan',
              correct: true,
            },
            {
              title: "Shaquille O'Neal",
              correct: false,
            },
            {
              title: 'Kareem Abdul-Jabbar',
              correct: false,
            },
            {
              title: 'Hakeem Olajuwon',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 2,
          opponentTotalScore: 3,
          answered_in: 7.8,
        },
        {
          question:
            "Who among the following players was not part of the 'Dream Team' in the 1992 Olympics?",
          answers: [
            {
              title: "Shaquille O'Neal",
              correct: true,
            },
            {
              title: 'Magic Johnson',
              correct: false,
            },
            {
              title: 'Michael Jordan',
              correct: false,
            },
            {
              title: 'Larry Bird',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 3,
          opponentTotalScore: 4,
          answered_in: 5.7,
        },
        {
          question: "Which player was known as 'The Answer'?",
          answers: [
            {
              title: 'Allen Iverson',
              correct: true,
            },
            {
              title: 'Kobe Bryant',
              correct: false,
            },
            {
              title: "Shaquille O'Neal",
              correct: false,
            },
            {
              title: 'Tim Duncan',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 4,
          opponentTotalScore: 5,
          answered_in: 5.5,
        },
        {
          question: 'Who was the number 1 pick in the 1997 NBA Draft?',
          answers: [
            {
              title: 'Tim Duncan',
              correct: true,
            },
            {
              title: 'Tracy McGrady',
              correct: false,
            },
            {
              title: 'Chauncey Billups',
              correct: false,
            },
            {
              title: 'Keith Van Horn',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 5,
          opponentTotalScore: 6,
          answered_in: 8.1,
        },
        {
          question: 'Who was the number 1 pick in the 2012 NBA Draft?',
          answers: [
            {
              title: 'Anthony Davis',
              correct: true,
            },
            {
              title: 'Bradley Beal',
              correct: false,
            },
            {
              title: 'Damian Lillard',
              correct: false,
            },
            {
              title: 'Harrison Barnes',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 6,
          opponentTotalScore: 7,
          answered_in: 2.5,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 7,
    },
    {
      id: 9,
      challenge_skill: 'hard',
      challenge_entry_fee: 3,
      challenge_reward: 6,
      challenger_id: 9,
      rounds: [
        {
          question: 'Who was the number 1 pick in the 1972 NBA Draft?',
          answers: [
            {
              title: 'LaRue Martin',
              correct: true,
            },
            {
              title: 'Bob McAdoo',
              correct: false,
            },
            {
              title: 'Julius Erving',
              correct: false,
            },
            {
              title: 'Artis Gilmore',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 0,
          opponentTotalScore: 1,
          answered_in: 4.3,
        },
        {
          question: "Which NBA team selected Shaquille O'Neal first overall in the 1992 NBA Draft?",
          answers: [
            {
              title: 'Orlando Magic',
              correct: true,
            },
            {
              title: 'Los Angeles Lakers',
              correct: false,
            },
            {
              title: 'Miami Heat',
              correct: false,
            },
            {
              title: 'Phoenix Suns',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 1,
          opponentTotalScore: 2,
          answered_in: 5.1,
        },
        {
          question: 'Who was the number 1 pick in the 2010 NBA Draft?',
          answers: [
            {
              title: 'John Wall',
              correct: true,
            },
            {
              title: 'DeMarcus Cousins',
              correct: false,
            },
            {
              title: 'Paul George',
              correct: false,
            },
            {
              title: 'Gordon Hayward',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 2,
          opponentTotalScore: 3,
          answered_in: 6.8,
        },
        {
          question: 'Who was the number 1 pick in the 2000 NBA Draft?',
          answers: [
            {
              title: 'Kenyon Martin',
              correct: true,
            },
            {
              title: 'Mike Miller',
              correct: false,
            },
            {
              title: 'Jamal Crawford',
              correct: false,
            },
            {
              title: 'Hedo Türkoğlu',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 3,
          opponentTotalScore: 40,
          answered_in: 6.3,
        },
        {
          question: 'Which NBA team had the first pick in the 1986 NBA Draft?',
          answers: [
            {
              title: 'Cleveland Cavaliers',
              correct: true,
            },
            {
              title: 'Portland Trail Blazers',
              correct: false,
            },
            {
              title: 'Chicago Bulls',
              correct: false,
            },
            {
              title: 'Los Angeles Lakers',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 40,
          opponentTotalScore: 41,
          answered_in: 3,
        },
        {
          question: 'Who was the first Asian player to be drafted into the NBA?',
          answers: [
            {
              title: 'Yao Ming',
              correct: true,
            },
            {
              title: 'Yuta Tabuse',
              correct: false,
            },
            {
              title: 'Rui Hachimura',
              correct: false,
            },
            {
              title: 'Jeremy Lin',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 41,
          opponentTotalScore: 42,
          answered_in: 4.3,
        },
        {
          question: 'Which NBA team selected LeBron James first overall in the 2003 NBA Draft?',
          answers: [
            {
              title: 'Cleveland Cavaliers',
              correct: true,
            },
            {
              title: 'Miami Heat',
              correct: false,
            },
            {
              title: 'Los Angeles Lakers',
              correct: false,
            },
            {
              title: 'Chicago Bulls',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 42,
          opponentTotalScore: 43,
          answered_in: 3.5,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 43,
    },
    {
      id: 10,
      challenge_skill: 'hard',
      challenge_entry_fee: 3,
      challenge_reward: 6,
      challenger_id: 1,
      rounds: [
        {
          question: 'Who was the number 1 pick in the 2017 NBA Draft?',
          answers: [
            {
              title: 'Markelle Fultz',
              correct: true,
            },
            {
              title: 'Lonzo Ball',
              correct: false,
            },
            {
              title: 'Jayson Tatum',
              correct: false,
            },
            {
              title: "De'Aaron Fox",
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 0,
          opponentTotalScore: 69,
          answered_in: 3.1,
        },
        {
          question: 'Who was the number 1 pick in the 2002 NBA Draft?',
          answers: [
            {
              title: 'Yao Ming',
              correct: true,
            },
            {
              title: "Amar'e Stoudemire",
              correct: false,
            },
            {
              title: 'Carlos Boozer',
              correct: false,
            },
            {
              title: 'Manu Ginóbili',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 69,
          opponentTotalScore: 70,
          answered_in: 8.5,
        },
        {
          question: 'Which NBA team had the first pick in the 1990 NBA Draft?',
          answers: [
            {
              title: 'New Jersey Nets',
              correct: true,
            },
            {
              title: 'Portland Trail Blazers',
              correct: false,
            },
            {
              title: 'Chicago Bulls',
              correct: false,
            },
            {
              title: 'Los Angeles Lakers',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 70,
          opponentTotalScore: 71,
          answered_in: 4.6,
        },
        {
          question: 'Who was the number 1 pick in the 2011 NBA Draft?',
          answers: [
            {
              title: 'Kyrie Irving',
              correct: true,
            },
            {
              title: 'Kawhi Leonard',
              correct: false,
            },
            {
              title: 'Klay Thompson',
              correct: false,
            },
            {
              title: 'Jimmy Butler',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 71,
          opponentTotalScore: 114,
          answered_in: 5.7,
        },
        {
          question: 'Who was the number 1 pick in the 1998 NBA Draft?',
          answers: [
            {
              title: 'Michael Olowokandi',
              correct: true,
            },
            {
              title: 'Dirk Nowitzki',
              correct: false,
            },
            {
              title: 'Paul Pierce',
              correct: false,
            },
            {
              title: 'Vince Carter',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 114,
          opponentTotalScore: 177,
          answered_in: 3.7,
        },
        {
          question: 'Who was the number 1 pick in the 1988 NBA Draft?',
          answers: [
            {
              title: 'Danny Manning',
              correct: true,
            },
            {
              title: 'Mitch Richmond',
              correct: false,
            },
            {
              title: 'Scottie Pippen',
              correct: false,
            },
            {
              title: 'Reggie Miller',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 177,
          opponentTotalScore: 178,
          answered_in: 7.2,
        },
        {
          question: 'Which NBA team selected Larry Bird in the 1978 NBA Draft?',
          answers: [
            {
              title: 'Boston Celtics',
              correct: true,
            },
            {
              title: 'Los Angeles Lakers',
              correct: false,
            },
            {
              title: 'New York Knicks',
              correct: false,
            },
            {
              title: 'Philadelphia 76ers',
              correct: false,
            },
          ],
          opponentAnswerPosition: 2,
          opponentPreviousScore: 178,
          opponentTotalScore: 179,
          answered_in: 4,
        },
      ],
      event: {
        id: '1',
        name: 'Basketball Trivia',
        slug: 'event_slug',
        banner_image_url: 'https://iili.io/HP9eBRI.png',
        profile_image_url: 'https://iili.io/HP9eaO7.png',
        seconds_left: 0,
        end_date: '',
        metadata: {
          total_challenges: 0,
          total_challenges_played: 0,
        },
        category: {
          id: 0,
          title: 'Sports',
        },
        subCategory: {
          id: 0,
          title: 'Basketball',
        },
        description:
          "Welcome to the ultimate basketball quiz! Test your knowledge about the game with these exciting questions. Are you ready to show off your basketball expertise? Let's get started!",
      },
      opponentTotalScore: 179,
    },
    {
      id: 11,
      challenge_skill: 'hard',
      challenge_entry_fee: 3,
      challenge_reward: 6,
      challenger_id: 1,
      rounds: [
        {
          question: 'Who was the number 1 pick in the 1977 NBA Draft?',
          answers: [
            {
              title: 'Kent Benson',
              correct: true,
            },
            {
              title: 'Bernard King',
              correct: false,
            },
            {
              title: 'Jack Sikma',
              correct: false,
            },
            {
              title: 'Walter Davis',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 0,
          opponentTotalScore: 75,
          answered_in: 2.5,
        },
        {
          question: 'Which NBA team selected Dwight Howard first overall in the 2004 NBA Draft?',
          answers: [
            {
              title: 'Orlando Magic',
              correct: true,
            },
            {
              title: 'Los Angeles Lakers',
              correct: false,
            },
            {
              title: 'Atlanta Hawks',
              correct: false,
            },
            {
              title: 'Houston Rockets',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 75,
          opponentTotalScore: 76,
          answered_in: 8,
        },
        {
          question: 'Who was the number 1 pick in the 2015 NBA Draft?',
          answers: [
            {
              title: 'Karl-Anthony Towns',
              correct: true,
            },
            {
              title: 'Kristaps Porziņģis',
              correct: false,
            },
            {
              title: 'Devin Booker',
              correct: false,
            },
            {
              title: "D'Angelo Russell",
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 76,
          opponentTotalScore: 77,
          answered_in: 7,
        },
        {
          question: 'Who was the number 1 pick in the 1990 NBA Draft?',
          answers: [
            {
              title: 'Derrick Coleman',
              correct: true,
            },
            {
              title: 'Gary Payton',
              correct: false,
            },
            {
              title: 'Chris Mullin',
              correct: false,
            },
            {
              title: 'Dennis Rodman',
              correct: false,
            },
          ],
          opponentAnswerPosition: 1,
          opponentPreviousScore: 77,
          opponentTotalScore: 78,
          answered_in: 3.2,
        },
        {
          question: 'Who was the last player selected in the 2003 NBA Draft?',
          answers: [
            {
              title: 'Andreas Glyniadakis',
              correct: true,
            },
            {
              title: 'LeBron James',
              correct: false,
            },
            {
              title: 'Dwyane Wade',
              correct: false,
            },
            {
              title: 'Carmelo Anthony',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 78,
          opponentTotalScore: 154,
          answered_in: 2.4,
        },
        {
          question: "Get Rich or Die Tryin' by 50 Cent went platinum how many times?",
          answers: [
            {
              title: '8',
              correct: true,
            },
            {
              title: '1',
              correct: false,
            },
            {
              title: '10',
              correct: false,
            },
            {
              title: '70',
              correct: false,
            },
          ],
          opponentAnswerPosition: 3,
          opponentPreviousScore: 154,
          opponentTotalScore: 155,
          answered_in: 5.6,
        },
        {
          question:
            'Arabian Prince, DJ Yella, Dr. Dre, Eazy-E, Ice Cube and MC Ren are better known as?',
          answers: [
            {
              title: 'NWA',
              correct: true,
            },
            {
              title: 'Wu-Tang Clan',
              correct: false,
            },
            {
              title: 'Run DMC',
              correct: false,
            },
            {
              title: 'Onyx',
              correct: false,
            },
          ],
          opponentAnswerPosition: 0,
          opponentPreviousScore: 155,
          opponentTotalScore: 187,
          answered_in: 6.8,
        },
      ],
      opponentTotalScore: 187,
    },
  ],
  event,
};
