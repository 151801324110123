import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { useLogin } from '../../hooks/login';
import User from './lib/User';
import Deposit from './lib/Deposit';
import { IUser } from '../../../types/api';
import { IAppData } from '../../app-context';
import { PLAYER_PROFILE_HIDDEN } from '../../analytics/types';
import { logEvent } from '../../analytics/amplitude';

interface IUserModal {
  isOpen: boolean;
  setIsOpenLogin: Dispatch<SetStateAction<boolean>>;
  user: IUser;
  userInfo: Partial<IAppData>;
}

function UserModal(props: IUserModal) {
  const { isOpen, user, userInfo, setIsOpenLogin } = props;
  const ref = useRef<SheetRef>();
  const loginProviders = useLogin({});
  const [depositState, setDepositState] = useState({
    show: false,
    processing: false,
    failed: false,
  });
  const { show, processing, failed } = depositState;

  const modalContentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setIsOpenLogin(false);
      logEvent(PLAYER_PROFILE_HIDDEN, {
        funnel_step: funnel_step,
        user_id: user?.id
      })
    }
  };

  let funnel_step = "tutorial_0";

  if (["/events/basketball"].indexOf(document.location.pathname) >= 0) {
    funnel_step = `regular`
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const initTimeout = setTimeout(
      () =>
        depositState.processing &&
        setDepositState({ show: false, processing: false, failed: true }),
      2000
    );
    return () => clearTimeout(initTimeout);
  }, [depositState.processing]);

  const handleOnClose = () => {
    logEvent(PLAYER_PROFILE_HIDDEN, {
      funnel_step: funnel_step,
      user_id: user?.id
    })

    setIsOpenLogin(false);
  }

  return (
    <Sheet
      isOpen={isOpen}
      onClose={handleOnClose}
      snapPoints={[520, 520, 0]}
      initialSnap={1}
      className="max-w-md mx-auto"
    >
      <Sheet.Container style={{ borderTopLeftRadius: '10%', borderTopRightRadius: '10%' }}>
        <Sheet.Content style={{ paddingBottom: ref.current?.y }} ref={modalContentRef}>
          {!show && !processing && !failed ? (
            <User setDepositState={setDepositState} user={user} userInfo={userInfo} />
          ) : (
            <Deposit
              loginProviders={loginProviders}
              depositState={depositState}
              setDepositState={setDepositState}
              user={user}
            />
          )}
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}

export default UserModal;
