import React, { useEffect } from 'react';
import { IAnswer, ITrivia, IUser } from '../../../types/api';
import PlayHeader from './lib/PlayHeader';
import PlayBody from './lib/PlayBody';
import ConfettiExplosion from 'react-confetti-explosion';
import usePageMeta from '../../../hooks/usePageMeta';
import { QUESTION_ATTEMPTED } from '../../analytics/types';
import { logEvent } from '../../analytics/amplitude';
import { IAppData } from '../../app-context';

interface IPlay {
  challengeId: string;
  trivia: ITrivia;
  handleAnswerClick: (answer: IAnswer) => void;
  answerSelected: {
    answer: IAnswer | null;
    answeredIn: number | null;
    opponentAnswer: IAnswer | null;
    userPickedFirst: boolean;
    startRound: boolean;
  };
  userData: IUser & IAppData;
  round: number;
}
const Play = (props: IPlay) => {
  const { challengeId, trivia, userData, handleAnswerClick, answerSelected, round } = props;

  let funnel_step = 'regular';

  if (['0', '1'].indexOf(challengeId) >= 0) {
    funnel_step = `tutorial_${challengeId}`;
  }

  useEffect(() => {
    if (answerSelected?.answer) {
      logEvent(QUESTION_ATTEMPTED, {
        funnel_step,
        game_mode: 'fwpvp',
        challenge_number: challengeId,
        user_id: userData?.id,
        round_number: trivia.currentRound,
        answer_outcome: answerSelected?.answer?.correct,
        time_taken: answerSelected?.answeredIn,
      });
    }
  }, [answerSelected?.answer]);

  usePageMeta({ themeColor: '#000000' });

  return (
    <div className="flex flex-col items-center justify-between bg-black overflow-y-auto overflow-x-hidden py-4 sm:w-full w-full">
      <PlayHeader
        trivia={trivia}
        userData={userData}
        answerSelected={answerSelected}
        userRoundScore={userData?.user_score}
        round={round}
      />
      <PlayBody
        challengeId={challengeId}
        trivia={trivia}
        handleAnswerClick={handleAnswerClick}
        answerSelected={answerSelected}
        userImage={userData?.profile_image_url}
        userData={userData}
      />
      {/*<PlayFooter round={round} />*/}
      {answerSelected?.answer?.correct && (
        <div className="absolute top-1/2">
          <ConfettiExplosion
            {...{
              force: 0.6,
              duration: 2500,
              particleCount: 80,
              width: 1000,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Play;
