import { Storage } from './storage';

export const SET_INFO_KEY = 'ogmi-storage/info/set';
export const USER_KEY = 'ogmi-storage-user-key';
export const USER_INFO_KEY = 'ogmi-storage-info-key';
export const USER_TOKEN = 'ogmi-storage-token-key';
export const TOTAL_ROUNDS = 7;
export const ROUND_TIME = 10;

//storage keys
export const ls_info = Storage(USER_INFO_KEY, { set: SET_INFO_KEY });
export const ls_user = Storage(USER_KEY);
export const ls_token = Storage(USER_TOKEN);
export const LS_FIRST_TIME_USER = 'FIRST_TIME_USER';

export const INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_MS = 2000;
export const ENABLE_TIMER_COUNTDOWN_TO_RUN_MS = 2000;

export const DURATION_OF_THE_RESPONSE_ANIMATION_SECONDS = 0.25;
export const DELAY_OF_THE_RESPONSE_ANIMATION_SECONDS = 1.75;
export const INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_SECONDS = 2;
export const ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS = 2;
export const ROUND_TRANSITION_TIME_SECONDS = 2;

//config cat keys
export const VARIANT_A_FLAG_KEY = 'variantA';
export const VARIANT_B_FLAG_KEY = 'variantB';
