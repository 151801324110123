import { useMutation } from 'react-query';
import api from '../../../config/api';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import type { IAnswer, ITrivia } from '../../../types/api';
import { isEmpty } from 'lodash';
import { isFirstToAnswer } from '../../../helpers/helpers';
import {
  ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS,
  INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_MS,
  INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_SECONDS,
  ROUND_TIME,
  ROUND_TRANSITION_TIME_SECONDS,
  TOTAL_ROUNDS,
} from '../../_shared/constants';
import { logEvent } from '../../analytics/amplitude';
import { QUESTION_ATTEMPTED } from '../../analytics/types';
import { useUser } from '../user';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getTrivia, setRound } from '../../redux/actions/trivia';
import { setChallengeIds, setGamePlayed, setUserScore } from '../../redux/actions/user';
import { useNavigate } from 'react-router-dom';

interface IUseTrivia {
  challengeId: number;
  slug: string;
}

interface IUseTriviaReturn {
  trivia: ITrivia;
  round: number;
  handleAnswerClick: (answer: IAnswer) => void;
  answerSelected: {
    answer: IAnswer | null;
    answeredIn: number | null;
    opponentAnswer: IAnswer | null;
    userPickedFirst: boolean;
    startRound: boolean;
  };
}

export const useTrivia = (props: IUseTrivia): IUseTriviaReturn => {
  const { challengeId, slug } = props;
  const dispatch = useAppDispatch();
  const { trivia, round } = useAppSelector((state) => state.trivia);
  const navigate = useNavigate();
  const params = {};
  const [startTime, setStartTime] = useState(null);
  const [answerSelected, setAnswerSelected] = useState({
    answer: null,
    answeredIn: null,
    opponentAnswer: null,
    userPickedFirst: true,
    startRound: true,
  });
  const opponentAnsweredIn =
    trivia?.opponent_round?.answered_in + INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_SECONDS;
  const { user } = useUser();

  const { mutateAsync: playRound } = useMutation(
    ['playChallenge'],
    async ({ challengeId, current_round }: { challengeId: number; current_round: number }) => {
      params['c_id'] = challengeId;
      params['current_round'] = current_round;

      await api.play({
        params,
        onSuccess: (data) => dispatch(getTrivia(data?.data)),
        onError: (response: { response: AxiosResponse<any> }) =>
          console.warn('there is an error with axios', response),
      });
    }
  );

  const resetLocalState = (allowRound = true) => {
    setStartTime(null);
    setAnswerSelected({
      answer: null,
      answeredIn: null,
      opponentAnswer: null,
      userPickedFirst: true,
      startRound: allowRound,
    });
  };

  const startRoundHandler = (current_round, timeDifference, answer) => {
    const isValidRound = current_round + 1 <= TOTAL_ROUNDS;

    const validRound = () => {
      resetLocalState();
      playRound({ challengeId, current_round: current_round + 1 });
    };
    const invalidRound = () => {
      resetLocalState(false);
      navigate({
        pathname: `/events/${slug}/challenges/${challengeId}/reward`,
        search: '?claim=true',
      });
    };

    //if no answer was selected during round
    if (!timeDifference) {
      if (isValidRound) {
        resetLocalState();
        dispatch(setRound());
        return playRound({ challengeId, current_round: current_round + 1 });
      }
      if (!isValidRound) {
        resetLocalState(false);
        dispatch(setChallengeIds(challengeId));
        dispatch(setGamePlayed());
        return navigate({
          pathname: `/events/${slug}/challenges/${challengeId}/reward`,
          search: '?claim=true',
        });
      }
    }

    //if answer was selected and round is valid
    if (timeDifference && isValidRound) {
      dispatch(setRound());
      dispatch(setUserScore({ isCorrect: answer?.correct, timeDifference: timeDifference - ROUND_TRANSITION_TIME_SECONDS - ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS }));
      if (timeDifference <= opponentAnsweredIn) {
        const initTimeout = setTimeout(
          () => validRound(),
          (opponentAnsweredIn -
            timeDifference +
            ROUND_TRANSITION_TIME_SECONDS +
            ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS) *
          1000
        );
        return () => clearTimeout(initTimeout);
      }
      if (timeDifference > opponentAnsweredIn) {
        const initTimeout = setTimeout(
          () => validRound(),
          (ROUND_TRANSITION_TIME_SECONDS + ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS) * 1000
        );
        return () => clearTimeout(initTimeout);
      }
    }

    //if answer was selected and round is over
    if (timeDifference && !isValidRound) {
      dispatch(setChallengeIds(challengeId));
      dispatch(setGamePlayed());
      dispatch(setUserScore({ isCorrect: answer?.correct, timeDifference }));
      if (timeDifference <= opponentAnsweredIn) {
        const initTimeout = setTimeout(
          () => invalidRound(),
          (opponentAnsweredIn -
            timeDifference +
            ROUND_TRANSITION_TIME_SECONDS +
            ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS) *
            1000
        );
        return () => clearTimeout(initTimeout);
      }
      if (timeDifference > opponentAnsweredIn) {
        const initTimeout = setTimeout(
          () => invalidRound(),
          (ROUND_TRANSITION_TIME_SECONDS + ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS) * 1000
        );
        return () => clearTimeout(initTimeout);
      }
    }
  };

  let funnel_step = 'regular';

  if ([0, 1].indexOf(challengeId) >= 0) {
    funnel_step = `tutorial_${challengeId}`;
  }

  const handleAnswerClick = (answer: IAnswer) => {
    if (startTime) {
      const currentTime = new Date(); // Current date and time

      const timeDifference = (currentTime.getTime() - startTime) / 1000;

      setAnswerSelected((prev) => ({
        ...prev,
        answer,
        answeredIn:
          timeDifference - ROUND_TRANSITION_TIME_SECONDS - ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS,
        userPickedFirst: !isFirstToAnswer(timeDifference, opponentAnsweredIn),
      }));

      startRoundHandler(trivia?.currentRound, timeDifference, answer);

      logEvent(QUESTION_ATTEMPTED, {
        funnel_step,
        challenge_number: challengeId,
        user_id: user?.id,
        round_number: trivia?.currentRound,
        answer_outcome: answer?.correct,
        time_taken: timeDifference - ROUND_TRANSITION_TIME_SECONDS - ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS,
        game_mode: 'fwpvp',
      });
    }
  };

  //restart round after ROUND_TIME elapse if nothing happens
  useEffect(() => {
    const initTimeout =
      !answerSelected.startRound &&
      isEmpty(answerSelected.answer) &&
      setTimeout(() => {
        startRoundHandler(trivia?.currentRound, null, null);
      }, (ROUND_TIME + ROUND_TRANSITION_TIME_SECONDS) * 1000);

    return () => clearTimeout(initTimeout);
  }, [answerSelected.startRound, answerSelected.answer]);

  //run playRound for first round
  useEffect(() => {
    playRound({
      challengeId,
      current_round: round || 1,
    });
  }, [challengeId]);

  //set start time to track clicked answer
  useEffect(() => {
    const initTimer =
      !isEmpty(trivia) &&
      setTimeout(
        () => setStartTime(new Date()),
        (ROUND_TRANSITION_TIME_SECONDS - ENABLE_TIMER_COUNTDOWN_TO_RUN_SECONDS) * 1000
      );
    return () => clearTimeout(initTimer);
  }, [trivia]);

  //set start round to false after 2secs
  useEffect(() => {
    const initTimeout =
      !isEmpty(trivia) &&
      setTimeout(() => {
        setAnswerSelected((prev) => ({
          ...prev,
          startRound: false,
        }));
      }, 2000);
    return () => clearTimeout(initTimeout);
  }, [trivia]);

  //set opponent answer based on answered time
  useEffect(() => {
    const initTimeout =
      !isEmpty(trivia) &&
      setTimeout(() => {
        setAnswerSelected((prev) => ({
          ...prev,
          opponentAnswer: trivia?.opponent_round?.opponent_answer,
          userPickedFirst: !prev.userPickedFirst,
        }));
      }, opponentAnsweredIn * 1000 + INCREASE_THE_DELAY_FOR_OPPONENT_RESPONSE_MS);
    return () => clearTimeout(initTimeout);
  }, [trivia]);

  return {
    trivia,
    round,
    handleAnswerClick,
    answerSelected,
  };
};
