import React, { useEffect } from 'react';
import { CTimer } from '../../../_shared/CTimer';
import { ROUND_TIME } from '../../../../_shared/constants';

interface ITimerCountDown {
  initCountdown?: boolean;
}

const TimerCountDown = (props: ITimerCountDown) => {
  const { initCountdown } = props;
  const { svg, animateTo } = CTimer({ size: 25, angle: 2 });

  useEffect(() => {
    if (initCountdown) animateTo(360, ROUND_TIME * 1000);
  }, [initCountdown]);

  return (
    <div className="w-[60px] flex flex-col text-center justify-center items-center mx-auto">
      <p style={{ color: '#4CCFFE' }} className={'text-xs truncate text-center'}>
        Time Left
      </p>
      <div className={'w-[60px] ml-1 mt-3 text-center mx-auto'}>{svg}</div>
    </div>
  );
};

TimerCountDown.displayName = 'TimerCountDown';

export default TimerCountDown;
