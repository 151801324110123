import { createServer, Response } from 'miragejs';
import data from './data';
import omit from 'lodash/omit';
import { challengers } from './challengers';

const isNumber = (value) => typeof value === 'number';

const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swapping elements
  }
  return array;
}

export const server = createServer({
  routes() {
    this.timing = 0;

    this.get(baseUrl + '/users/:user_id', (schema, request) => {
      const { user_id } = request.params;
      const challenger = challengers.find((challenger) => challenger.id == user_id);
      return challenger ? challenger : new Response(404);
    });

    this.get(baseUrl + '/events/*', () => data.event);

    this.get(baseUrl + '/ranking_logs', () => {
      const users = challengers.sort(
        (player, next) => next.statistic.score - player.statistic.score
      );
      return users;
    });

    this.get(baseUrl + '/leaderboard', () => {
      const users = challengers.sort(
        (player, next) => next.statistic.score - player.statistic.score
      );
      return users;
    });

    this.get(baseUrl + '/challenges/:challengeId', (schema, request) => {
      const { challengeId } = request.params;

      const challenge = data.challenges.find((challenge) => challenge.id == challengeId);

      return challenge;
    });

    this.get(baseUrl + '/challenges', () => {
      const formattedChallenge = data.challenges.map((challenge) => {
        const formatted_fee = isNumber(challenge.challenge_entry_fee)
          ? challenge.challenge_entry_fee.toFixed(2)
          : challenge.challenge_entry_fee;
        const formatted_reward = isNumber(challenge.challenge_reward)
          ? challenge.challenge_reward.toFixed(2)
          : challenge.challenge_reward;
        const challenger = challengers.find((user) => +user.id === challenge.challenger_id);
        return {
          ...challenge,
          challenger,
          challenge_reward: formatted_reward,
          challenge_entry_fee: formatted_fee,
        };
      });
      return formattedChallenge;
    });

    this.post(baseUrl + '/matching', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      const { challenge_id } = attrs;
      const challenge = data.challenges.find((value) => value.id == challenge_id);

      if (isNumber(challenge.challenge_entry_fee)) {
        const formatted_fee = challenge.challenge_entry_fee.toFixed(2);
        challenge.challenge_entry_fee = formatted_fee;
      }

      if (isNumber(challenge.challenge_reward)) {
        const formatted_reward = challenge.challenge_reward.toFixed(2);
        challenge.challenge_reward = formatted_reward;
      }

      challenge.event = data.event;
      const opponent = challengers.find((value) => value.id == challenge.challenger_id);
      return { opponent, challenge };
    });

    this.post(baseUrl + '/trivia', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      const { c_id: challenge_id, current_round } = attrs;

      if (current_round <= 0 || current_round >= 8) {
        return {
          code: 'challenge completed',
          game_mode: 'pvp',
        };
      }

      const challenge = data.challenges.find((challenge) => challenge.id == challenge_id);
      const round = challenge.rounds[current_round - 1];
      const opponent = challengers.find((value) => +value.id === challenge.challenger_id);
      const opponent_answer = round.answers.find((_, i) => i === round.opponentAnswerPosition);
      round.answers = shuffleArray(round.answers);
      round.answers.map((answer, index) => {
        if (answer.title === opponent_answer.title) {
          round.opponentAnswerPosition = index;
        }
      })

      return {
        code: 'challenge in progress',
        game_mode: 'pvp',
        currentRound: current_round,
        round: omit(round, [
          'opponentAnswerPosition',
          'opponentPreviousScore',
          'opponentTotalScore',
          'answered_in',
        ]),
        opponent,
        opponent_round: {
          opponent_answer,
          total_score: round?.opponentTotalScore,
          ...omit(round, ['answers', 'question', 'opponentTotalScore']),
        },
      };
    });

    this.passthrough(baseUrl + '/users/me');
    this.passthrough('https://api2.amplitude.com/**');
    this.passthrough(baseUrl + '/auth/auth_token', 'POST');
    this.passthrough(baseUrl + '/social/google', 'POST');
    this.passthrough(baseUrl + '/auth/facebook/callback');
    this.passthrough('https://q.clarity.ms/**');
    this.passthrough('https://{subdomain}.clarity.ms/**');
    this.passthrough('https://x.clarity.ms/collect', 'POST');
    this.passthrough('https://firebaseinstallations.googleapis.com/*', 'POST');
    this.passthrough('https://r.clarity.ms/collect', 'POST');
    this.passthrough('https://firebase.googleapis.com/**');
    this.passthrough('https://www.facebook.com/platform/impression.php/**');
    this.passthrough('https://{subdomain}.facebook.com/platform/impression.php/**');
    this.passthrough('https://platform-lookaside.fbsbx.com/platform/profilepic/**');
    this.passthrough('https://cdn-global.configcat.com/**');

    // this.passthrough('https://lh3.googleusercontent.com/*');
    // this.passthrough('*');
    this.passthrough();
  },
});

server.logging = true; // Log requests/responses to the console
