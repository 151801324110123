import { useFeatureFlag, User } from 'configcat-react';
import { VARIANT_A_FLAG_KEY, VARIANT_B_FLAG_KEY } from '../../_shared/constants';
import amplitude from 'amplitude-js';
import { useEffect, useState } from 'react';
import { AMPLITUDE_API_KEY } from '../../analytics/types';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getVariantA, getVariantB } from '../../redux/actions/variants';

export interface Ivariants {
  spotlight: boolean;
  singleChallenge: boolean;
}

interface IUseFeaturesReturnValues {
  variant: Ivariants;
  variantAFlag: boolean;
  variantALoading: boolean;
  variantBFlag: boolean;
  variantBLoading: boolean;
}

interface IUseFeatures {
  variantADefaultValue?: boolean;
  variantBDefaultValue?: boolean;
}

const useFeatures = (props: IUseFeatures): IUseFeaturesReturnValues => {
  const { variantADefaultValue = false, variantBDefaultValue = false } = props;
  const dispatch = useAppDispatch();
  const { variant } = useAppSelector((state) => state.variants);
  const [user, setUser] = useState();

  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_API_KEY, null, null, function (instance) {
      setUser(instance.options.deviceId);
    });
  }, [user]);

  //VARIANT A
  const variantAObject = new User(user);
  const { value: variantAFlag, loading: variantALoading } = useFeatureFlag(
    VARIANT_A_FLAG_KEY,
    variantADefaultValue,
    variantAObject
  );

  //VARIANT B
  const variantBObj = {
    ...variantAObject,
    custom: { variantAFlag: String(variantAFlag) },
  };
  const { value: variantBFlag, loading: variantBLoading } = useFeatureFlag(
    VARIANT_B_FLAG_KEY,
    variantBDefaultValue,
    variantBObj
  );

  useEffect(() => {
    if (variantAFlag) dispatch(getVariantA());
    if (variantBFlag) dispatch(getVariantB());
  }, [variantAFlag, variantBFlag]);

  return {
    variant,
    variantAFlag,
    variantALoading,
    variantBFlag,
    variantBLoading,
  };
};

export default useFeatures;
