export const GET_VARIANT_A = '@get/variant-A';
export const GET_VARIANT_B = '@get/variant-B';

export const getVariantA = () => ({
  type: GET_VARIANT_A,
});

export const getVariantB = () => ({
  type: GET_VARIANT_B,
});
