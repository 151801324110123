import React, { createContext, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

import rotateMobileLogo from './../../assets/svgs/rotate_mobile.svg';

export const OrientationContext = createContext(null);

export const OrientationProvider = ({ children }) => {
  // Check if Screen Orientation API is available and whether it's in landscape mode
  const isLandscapeDefault = window.screen.orientation ? window.screen.orientation.type.startsWith('landscape') : window.innerWidth > window.innerHeight;

  const [isLandscape, setIsLandscape] = useState(isLandscapeDefault && !isDesktop);

  useEffect(() => {
    if (!isDesktop) {
      const handleOrientationChange = () => {
        if (window.screen.orientation) {
          setIsLandscape(window.screen.orientation.type.startsWith('landscape'));
        } else {
          // If Screen Orientation API is not available, use aspect ratio as a fallback
          setIsLandscape(window.innerWidth > window.innerHeight);
        }
      }

      window.addEventListener('orientationchange', handleOrientationChange);
      window.addEventListener('resize', handleOrientationChange);

      return () => {
        window.removeEventListener('orientationchange', handleOrientationChange);
        window.removeEventListener('resize', handleOrientationChange);
      };
    }
  }, []);

  return (
    <OrientationContext.Provider value={isLandscape}>
      {isLandscape ?
        <div className="fixed top-0 left-0 w-full h-full bg-primary text-white flex items-center justify-center z-50 flex flex-col">
          <img src={rotateMobileLogo} />
          <p className='text-center font-bold text-2xl mt-16'>Please rotate your device to continue playing</p>
        </div>
        : children
      }
    </OrientationContext.Provider>
  );
}
