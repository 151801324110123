import { useQuery } from 'react-query';
import api from '../../../config/api';
import { IWagerChallenge } from '../../../types/api';
import { getChallenges } from '../../redux/actions/challenges';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

interface IUseChallengeReturn {
  challenges: IWagerChallenge[];
  challenge: IWagerChallenge;
}

export const useChallenge = ({ challengeId }: { challengeId?: number }): IUseChallengeReturn => {
  const dispatch = useAppDispatch();
  const { challenges } = useAppSelector((state) => state.challenges);
  const [challenge, setChallenge] = useState<IWagerChallenge>();

  useQuery(['challenges'], async () => {
    const response = await api.fetchChallenges({ params: {} });
    if (response.data) dispatch(getChallenges(response.data));
  });

  useEffect(() => {
    if (challengeId && !isEmpty(challenges)) {
      setChallenge(challenges.find(({ id }) => id === challengeId));
    }
  }, [challengeId, challenges]);

  return {
    challenges,
    challenge,
  };
};
