export const challengers = [
  {
    "id": "1",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Aurora Wolfe.png",
    "display_name": "Aurora Wolfe",
    "statistic": {
      "total_games": 55,
      "total_wins": 24,
      "score": 8
    },
    "rank": 1
  },
  {
    "id": "2",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Gabriel Cruz.png",
    "display_name": "Gabriel Cruz",
    "statistic": {
      "total_games": 33,
      "total_wins": 4,
      "score": 4
    },
    "rank": 2
  },
  {
    "id": "3",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Maya Patel.png",
    "display_name": "Maya Patel",
    "statistic": {
      "total_games": 35,
      "total_wins": 4,
      "score": 7
    },
    "rank": 3
  },
  {
    "id": "4",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Oliver Jensen.png",
    "display_name": "Oliver Jensen",
    "statistic": {
      "total_games": 59,
      "total_wins": 20,
      "score": 3
    },
    "rank": 4
  },
  {
    "id": "5",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Isabella Chang.png",
    "display_name": "Isabella Chang",
    "statistic": {
      "total_games": 50,
      "total_wins": 30,
      "score": 3
    },
    "rank": 5
  },
  {
    "id": "6",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Ethan Khan.png",
    "display_name": "Ethan Khan",
    "statistic": {
      "total_games": 43,
      "total_wins": 24,
      "score": 8
    },
    "rank": 6
  },
  {
    "id": "7",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Lily Campbell.png",
    "display_name": "Lily Campbell",
    "statistic": {
      "total_games": 45,
      "total_wins": 24,
      "score": 3
    },
    "rank": 7
  },
  {
    "id": "8",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Sebastian Mendoza.png",
    "display_name": "Sebastian Mendoza",
    "statistic": {
      "total_games": 60,
      "total_wins": 6,
      "score": 5
    },
    "rank": 8
  },
  {
    "id": "9",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Amelia Liu.png",
    "display_name": "Amelia Liu",
    "statistic": {
      "total_games": 46,
      "total_wins": 16,
      "score": 4
    },
    "rank": 9
  },
  {
    "id": "10",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Noah Santos.png",
    "display_name": "Noah Santos",
    "statistic": {
      "total_games": 38,
      "total_wins": 22,
      "score": 2
    },
    "rank": 10
  },
  {
    "id": "11",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Ava Thompson.png",
    "display_name": "Ava Thompson",
    "statistic": {
      "total_games": 53,
      "total_wins": 31,
      "score": 7
    },
    "rank": 11
  },
  {
    "id": "12",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Alexander Ramirez.png",
    "display_name": "Alexander Ramirez",
    "statistic": {
      "total_games": 60,
      "total_wins": 19,
      "score": 6
    },
    "rank": 12
  },
  {
    "id": "13",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Charlotte Park.png",
    "display_name": "Charlotte Park",
    "statistic": {
      "total_games": 56,
      "total_wins": 22,
      "score": 5
    },
    "rank": 13
  },
  {
    "id": "14",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Benjamin Fitzgerald.png",
    "display_name": "Benjamin Fitzgerald",
    "statistic": {
      "total_games": 48,
      "total_wins": 18,
      "score": 5
    },
    "rank": 14
  },
  {
    "id": "15",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Sophia Costa.png",
    "display_name": "Sophia Costa",
    "statistic": {
      "total_games": 43,
      "total_wins": 14,
      "score": 8
    },
    "rank": 15
  },
  {
    "id": "16",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Liam Nguyen.png",
    "display_name": "Liam Nguyen",
    "statistic": {
      "total_games": 54,
      "total_wins": 28,
      "score": 4
    },
    "rank": 16
  },
  {
    "id": "17",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Harper Ellis.png",
    "display_name": "Harper Ellis",
    "statistic": {
      "total_games": 56,
      "total_wins": 20,
      "score": 17
    },
    "rank": 17
  },
  {
    "id": "18",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Jameson Martinez.png",
    "display_name": "Jameson Martinez",
    "statistic": {
      "total_games": 56,
      "total_wins": 23,
      "score": 14
    },
    "rank": 18
  },
  {
    "id": "19",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Evelyn Wang.png",
    "display_name": "Evelyn Wang",
    "statistic": {
      "total_games": 30,
      "total_wins": 11,
      "score": 16
    },
    "rank": 19
  },
  {
    "id": "20",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Samuel Brooks.png",
    "display_name": "Samuel Brooks",
    "statistic": {
      "total_games": 59,
      "total_wins": 12,
      "score": 16
    },
    "rank": 20
  },
  {
    "id": "21",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Mia Foster.png",
    "display_name": "Mia Foster",
    "statistic": {
      "total_games": 40,
      "total_wins": 13,
      "score": 15
    },
    "rank": 21
  },
  {
    "id": "22",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Elijah Lee.png",
    "display_name": "Elijah Lee",
    "statistic": {
      "total_games": 51,
      "total_wins": 13,
      "score": 14
    },
    "rank": 22
  },
  {
    "id": "23",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Scarlett Phillips.png",
    "display_name": "Scarlett Phillips",
    "statistic": {
      "total_games": 38,
      "total_wins": 21,
      "score": 10
    },
    "rank": 23
  },
  {
    "id": "24",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Henry Stewart.png",
    "display_name": "Henry Stewart",
    "statistic": {
      "total_games": 35,
      "total_wins": 12,
      "score": 14
    },
    "rank": 24
  },
  {
    "id": "25",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Grace Rodriguez.png",
    "display_name": "Grace Rodriguez",
    "statistic": {
      "total_games": 54,
      "total_wins": 31,
      "score": 16
    },
    "rank": 25
  },
  {
    "id": "26",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Daniel Clarke.png",
    "display_name": "Daniel Clarke",
    "statistic": {
      "total_games": 57,
      "total_wins": 27,
      "score": 14
    },
    "rank": 26
  },
  {
    "id": "27",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Penelope Adams.png",
    "display_name": "Penelope Adams",
    "statistic": {
      "total_games": 34,
      "total_wins": 14,
      "score": 20
    },
    "rank": 27
  },
  {
    "id": "28",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/William Sullivan.png",
    "display_name": "William Sullivan",
    "statistic": {
      "total_games": 30,
      "total_wins": 9,
      "score": 17
    },
    "rank": 28
  },
  {
    "id": "29",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Victoria Taylor.png",
    "display_name": "Victoria Taylor",
    "statistic": {
      "total_games": 59,
      "total_wins": 28,
      "score": 15
    },
    "rank": 29
  },
  {
    "id": "30",
    "balance": null,
    "profile_image_url": "https://api.multiavatar.com/Michael Wright.png",
    "display_name": "Michael Wright",
    "statistic": {
      "total_games": 54,
      "total_wins": 31,
      "score": 10
    },
    "rank": 30
  }
]