import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../_shared/Header';
import RoundedUserProfileImage from '../_shared/RoundUserProfileImage';
import lostMemeGif from '../../../assets/gifs/john-travolta.gif';
import halfCenterCircle from '../../../assets/images/half_center_circle.png';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-1 flex-col bg-primary overflow-y-auto">
      <img
        src={halfCenterCircle}
        className="absolute transform transform-y-1/2 lg:hidden object-cover w-full max-w-md"
      />

      <Header hasTwitter hasDiscord title="Feeling lost?" />

      <div className="flex flex-1 flex-col items-center mt-10">
        <RoundedUserProfileImage
          userProfileImage={lostMemeGif}
          extraClassName="bg-primary w-40 h-40"
        />
        <p className="mt-8 text-gray-400 font-bold block text-2xl">Don&apos;t be him</p>
      </div>

      <div className="mb-12">
        <div className="mb-4">
          <p className="pl-4 text-gray-400 font-bold block text-2xl">More events to try!</p>
        </div>
        <div className="px-4 w-full">
          <div
            onClick={() => navigate('/events')}
            className="flex justify-center items-center bg-pink-500 h-14 text-white font-bold rounded-xl cursor-pointer active:bg-pink-600 w-full"
          >
            <p className="text-xl">Check it out</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
