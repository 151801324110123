export const GET_CHALLENGES = '@get/challenges';
export const GET_CHALLENGE = '@get/challenge';

export const getChallenges = (payload) => ({
  type: GET_CHALLENGES,
  payload,
});

export const getChallenge = (payload) => ({
  type: GET_CHALLENGE,
  payload,
});
