import OgmiLogo from './../../../../assets/images/ogmi_neon.png';
import basketballLogo from './../../../../assets/images/basketball.png';
import React, { useEffect } from 'react';
import { logEvent } from '../../../../analytics/amplitude';
import { ROUND_TRANSITION_0_SHOWN } from '../../../../analytics/types';
import usePageMeta from '../../../../../hooks/usePageMeta';
import useChallenge from '../../../../hooks/challenge/useChallenge';

const RoundTransition = ({ currentRound, totalRounds, challengeId, userId }) => {
  const { challenge } = useChallenge({ challengeId });
  const categoryTitle = challenge?.event?.category?.title;
  const subCategoryTitle = challenge?.event?.subCategory?.title;

  usePageMeta({ themeColor: '#000000' });

  let funnel_step = 'regular';

  if (['0', '1'].indexOf(challengeId) >= 0) {
    funnel_step = `tutorial_${challengeId}`;
  }

  useEffect(() => {
    if (currentRound == 1) {
      logEvent(ROUND_TRANSITION_0_SHOWN, {
        funnel_step,
        game_mode: 'fwpvp',
        challenge_number: challengeId,
        user_id: userId,
      });
    }
  }, [currentRound]);

  return (
    <div className={`flex flex-1 flex-col justify-center bg-black`}>
      <div className="flex flex-1 flex-col mt-[13px] mb-12">
        <img src={OgmiLogo} className="w-[76px] h-[75px] mx-auto" />
        <img src={basketballLogo} className="w-[62px] h-[62px] mt-[55px] mx-auto" />
        <div className="flex flex-col px-4 justify-center text-white">
          <p className="mt-[9px] text-[22px] font-bold text-center opacity-60">Quiz topic</p>

          <div className="text-white text-[32px] font-bold space-x-2 text-center">
            <span>{categoryTitle}</span>
            <span>x</span>
            <span>{subCategoryTitle}</span>
          </div>

          <h2 className="mt-[41px] leading-none text-80 font-bold text-center">{currentRound}</h2>
          <h2 className="leading-none text-50 font-bold text-center">Round</h2>
          <h2 className="text-md font-bold text-center text-gray-400 ">
            {currentRound} of {totalRounds}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default RoundTransition;
