import React from 'react';
import { getCircle, getSector, IOptions } from './utils';

export const CTimer = (options: IOptions) => {
  const element = document?.querySelector('.timer');
  const sector = element?.querySelector('.timer-sector');

  //default options
  const defaultOptions: IOptions = {
    size: 100,
    stroke: 6,
    arc: false,
    angle: 225,
    strokeColor: '#4CCFFE',
    sectorFill: '#4CCFFE',
    sectorColor: '#4CCFFE',
    circleColor: '#DDD',
    fillCircle: false,
  };

  // Merge options with default ones
  options = Object.assign(defaultOptions, options);

  // Reset stroke to 0 if drawing full sector
  options.stroke = options.arc ? options.stroke : 0;

  // Circle dimensions
  options.center = options.size / 2;
  options.radius = options.stroke ? options.center - options.stroke / 2 : options.center;

  //check if angle is > 360
  const checkAngle = (options: IOptions) =>
    options.angle > 360 && (options.angle = options.angle % 360);

  //call check angle
  checkAngle(options);

  //change angle
  const changeAngle = (angle: number, options: IOptions) => {
    options.angle = angle;
    checkAngle(options);
    getSector(true, options);
    sector?.setAttribute('d', getSector(true, options) as string);
  };

  //add steps
  const step = function (angleOffset, endAngle, time, endTime, options) {
    const now = new Date().valueOf();
    const timeOffset = endTime - now;
    if (timeOffset <= 0) {
      changeAngle(endAngle, options);
    } else {
      const angle = endAngle - (angleOffset * timeOffset) / time;
      changeAngle(angle, options);
      requestAnimationFrame(() => step(angleOffset, endAngle, time, endTime, options));
    }
  };

  //animate
  const animateTo = (angle, time = 300) => {
    if (angle > 360) {
      angle = angle % 360;
    }
    const startTime = new Date().valueOf();
    const endTime = startTime + time;
    const angleOffset = angle - options.angle;
    requestAnimationFrame(() => step(angleOffset, angle, time, endTime, options));
  };

  const svg = (
    <svg
      className="timer"
      viewBox={`-15 10 ${options.size} ${options.size}`}
      height={options.size}
      width={options.size}
      style={{ overflow: 'visible' }}
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={options.center}
        cy={options.center}
        r={options.radius - 1}
        fill={`none`}
        stroke={options.strokeColor}
        strokeWidth={2}
      />
      {getCircle(options)}
      {getSector(false, options)}
      <circle
        cx={options.center}
        cy={options.center}
        r={0}
        fill={'#000'}
        stroke={'#4CCFFE'}
        strokeWidth={1}
      />
    </svg>
  );

  return {
    svg,
    animateTo,
  };
};
