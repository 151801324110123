import React, { useContext } from 'react';
import Page from '../../components/_shared/Page';
import Play from '../../components/Play';
import { useTrivia } from '../../hooks/trivia';
import { isEmpty } from 'lodash';
import { Preloader, Spinning } from 'react-preloader-icon';
import { TOTAL_ROUNDS } from '../../_shared/constants';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../app-context';
import RoundTransition from '../../components/Play/lib/RoundTransition';
import usePageMeta from '../../../hooks/usePageMeta';

function PlayWrapper() {
  const { challengeId, slug } = useParams();
  const { userData, appData } = useContext(AppContext);
  const { trivia, handleAnswerClick, answerSelected, round } = useTrivia({
    challengeId: +challengeId,
    slug,
  });

  usePageMeta({ themeColor: '#000000' });

  return (
    <Page hasLogo className="bg-black">
      {isEmpty(trivia) && (
        <div className={'flex flex-col justify-center'}>
          <Preloader
            use={Spinning}
            size={60}
            strokeWidth={6}
            strokeColor="lightblue"
            duration={2000}
            className={'inline-flex justify-center'}
          />
        </div>
      )}
      {!isEmpty(trivia) && answerSelected.startRound && (
        <RoundTransition
          totalRounds={TOTAL_ROUNDS}
          currentRound={round}
          challengeId={challengeId}
          userId={userData?.user?.id}
        />
      )}
      {!isEmpty(trivia) && !answerSelected.startRound && (
        <Play
          challengeId={challengeId}
          trivia={trivia}
          handleAnswerClick={handleAnswerClick}
          answerSelected={answerSelected}
          round={round}
          userData={{ ...userData?.user, ...appData }}
        />
      )}
    </Page>
  );
}

export default PlayWrapper;
