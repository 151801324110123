import React from 'react';
import { isMobile } from 'react-device-detect';

import blankProfileImage from './../../../assets/images/blank_profile_picture.png';

function RoundedUserProfileImage({ userProfileImage, extraClassName, handleOnClick }: { userProfileImage?: string; extraClassName?: string, handleOnClick?: () => void }) {

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    if (handleOnClick) {
      if (isMobile) {
        const text = "Are you sure to logout?";
        if (confirm(text) == true) {
          handleOnClick?.();
        }
      } else {
        handleOnClick?.();
      }
    }
  }

  if (!userProfileImage) {
    userProfileImage = blankProfileImage
  }

  return (
    <div className={`relative flex w-38 h-38 bg-gray-300 rounded-full mx-auto justify-center items-center border-gray-400 cursor-pointer active:bg-gray-200 ${extraClassName}`} onClick={onClick}>
      <img referrerPolicy='no-referrer' src={userProfileImage} className='rounded-full w-36 h-36' />
      {
        !!handleOnClick && <div
          className="flex items-center justify-center rounded-full absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,0%,0.4)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
          <span className='text-gray-200 font-semibold'>Logout</span>
        </div>
      }
    </div>
  )
}

export default RoundedUserProfileImage;