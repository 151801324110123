import React from 'react';
import SplashScreen from '../../components/SplashScreen';
import Page from '../../components/_shared/Page';
import usePageMeta from '../../../hooks/usePageMeta';

function SplashScreenWrapper() {
  usePageMeta({});

  return (
    <Page className="bg-primary">
      <SplashScreen />
    </Page>
  );
}

export default SplashScreenWrapper;
